import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import TranslatedTypography from "../Language/TranslatedTypography";

export default function ControlledButtonGroup({ condition, value, name, defaultVal, members, onChange, ...props }) {
    if (!condition) return <></>;
    return (
        <>
            <ToggleButtonGroup
                color="primary"
                exclusive
                name={name}
                value={value}
                onChange={(_, type) => {
                    if (type === null) return;
                    onChange(type);
                }}
                {...props}
            >
                {members?.map((member) => (
                    <ToggleButton value={member.name}>
                        <TranslatedTypography>{member.label}</TranslatedTypography>
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            <input type="hidden" name={name} value={value}></input>
        </>
    );
}
