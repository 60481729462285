import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Carousel from "react-material-ui-carousel";
import { useState } from "react";
import Image from "../Image";

const Media = ({ definition, imgProps, ...props }) => {
    if (definition?.type === "image")
        return (
            <Image
                src={definition?.path}
                sx={{
                    maxHeight: "400px",
                    width: "100%",
                    objectFit: "scale-down",
                    objectPosition: "center",
                    ...imgProps,
                }}
                {...props}
            />
        );
    if (definition?.type === "embeddedYoutube")
        return (
            <Box sx={{ height: "100%", width: "100%" }} {...props}>
                <iframe
                    title={definition.href}
                    src={`https://www.youtube.com/embed/${definition.code}`}
                    style={{ height: "100%", width: "80%" }}
                ></iframe>
            </Box>
        );
    return <></>;
};

export default function MediaCarosel({ medias, imgProps = {}, ...props }) {
    const [firstImageLoaded, setFirstImageLoaded] = useState(false);

    return (
        <Box sx={{ margin: "0 auto 0 auto" }}>
            {medias && medias[0] && (
                /**Carosel is not loaded correctly on init. Issue on github open for years: https://github.com/Learus/react-material-ui-carousel/issues/189 */
                <Media
                    definition={medias[0]}
                    onLoad={() => {
                        setFirstImageLoaded(true);
                    }}
                    sx={{ display: "none" }}
                />
            )}
            {firstImageLoaded && (
                <Carousel navButtonsAlwaysVisible navButtonsWrapperProps={{ bottom: 0 }} {...props}>
                    {medias?.map((media, index) => (
                        <Stack sx={{ height: "400px" }} justifyContent="center" alignItems="center">
                            <Media definition={media} key={index} imgProps={imgProps} />
                        </Stack>
                    ))}
                </Carousel>
            )}
        </Box>
    );
}
