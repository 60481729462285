import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Logo from "./Logo";
import NavigationMenu from "../navigationMenu/NavigationMenu";
import { createContext } from "react";
import { useBoxWidth } from "../../hooks/useBoxWidth";

export const contentWidthContext = createContext(0);

export const siteWidthOptions = {
    maxWidth: "1300px",
    width: { xs: "95%", sm: "95%", md: "80%" },
    margin: "0 auto 0 auto",
};

function Header({ children, ...props }) {
    const { width, ref } = useBoxWidth();

    return (
        <contentWidthContext.Provider value={width}>
            <Box sx={{ height: "93px" }}></Box>
            <Box
                sx={{
                    width: "100%",
                    borderBottom: "4px solid",
                    borderColor: "#8B0000",
                    backgroundColor: "white.main",
                    height: "93px",
                    top: 0,
                    position: "fixed",
                    zIndex: 100,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        borderBottom: "2px solid",
                        borderColor: "#FF0000",
                        height: "100%",
                    }}
                >
                    <Box sx={siteWidthOptions}>
                        <Stack direction="row" justifyContent="space-between">
                            <Logo></Logo>
                            <NavigationMenu></NavigationMenu>
                        </Stack>
                        <Box ref={ref} sx={{ height: 0, width: "100%" }}></Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={siteWidthOptions}>{children}</Box>
        </contentWidthContext.Provider>
    );
}

export default Header;
