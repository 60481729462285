import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import FormattedText from "../formattedText/FormattedText";
import TranslatedTypography from "../Language/TranslatedTypography";

const Img = styled("img")``;

function Ad({ title, link, image, desc, customButtonText, ...props }) {
    const customSmallMatch = useMediaQuery("(min-width:400px)");
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(link);
    };

    return (
        <Box
            sx={{
                marginTop: 1,
            }}
            {...props}
        >
            <Box
                sx={{
                    backgroundColor: "white.main",
                    padding: 2,
                    boxShadow: 3,
                    borderRadius: 3,
                }}
            >
                <Stack direction="column" justifyContent="flex-start" spacing={2}>
                    <Stack direction={"column"} justifyContent="space-between" spacing={2}>
                        <TranslatedTypography variant={customSmallMatch ? "h4" : "h5"}>{title}</TranslatedTypography>
                        <FormattedText value={desc} readOnly={true} theme={false} />
                        <Box>
                            <Img src={image} sx={{ height: "150px" }} />
                        </Box>
                    </Stack>
                    <Button variant="contained" onClick={handleClick} color="triadicGreen" sx={{ paddingTop: 1 }}>
                        {customButtonText ?? "Mer info"}
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}

export default Ad;
