import Options, { prettifyOptions } from "./Options";
import Number, { prettifyNumbers } from "./Number";

export default function Choice(props) {
    return (
        <>
            {(props?.suboptions?.type === undefined || props.suboptions.type === "option") && (
                <Options {...props}></Options>
            )}
            {props?.suboptions?.type === "number" && <Number {...props} />}
        </>
    );
}

export const prettifySuboption = (definition, option, values) => {
    if (option.suboption?.type === undefined || option.suboption?.type === "option")
        return prettifyOptions(definition, option, values);
    if (option.suboption?.type === "number") return prettifyNumbers(definition, option, values);
};
