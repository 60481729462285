import "./App.css";
import API from "./API/API";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Typography from "@mui/material/Typography";
import theme from "./style/theme";
import Box from "@mui/material/Box";
import { createBrowserRouter, RouterProvider, useRouteError } from "react-router-dom";
import Root from "./Root";
import AlertHandler from "./components/AlertHandler/AlertHandler";
import CookieHandler from "./components/CookieHandler/CookieHandler";
import AccountManager from "./components/accountManager/AccountManager";
import "react-quill/dist/quill.snow.css";

import UsedList, { loader as usedListLoader, action as usedListAction } from "./components/used/UsedList";
import UsedDialog, { loader as usedDialogLoader, action as usedDialogAction } from "./components/usedDialog/UsedDialog";
import Newsletter, { action as NewsletterDialogAction } from "./components/used/NewsletterDialog";

import New from "./components/new/New";
import ChamberOven, { action as chamberOvenAction } from "./components/new/ChamberOven";

import Contact from "./components/contact/Contact";

import Showroom, { action as showroomAction } from "./components/showroom/Showroom";
import User, { action as userAction, loader as userLoader } from "./components/user/User";
import AddUsed, { loader as addUsedLoader, action as addUsedAction } from "./components/user/AddUsed";

import Login, { action as loginAction } from "./components/login/Login";

export const _API = new API(/(https?:\/\/)?[^/]*/.exec(window.location.href)[0], false);

const GenereicError = ({ children, ...props }) => {
    const error = useRouteError();

    return (
        <Typography>
            Nåt gick fel. Försök uppdatera sidan och försök igen. <br />
            <br />
            Felmeddelande:
            <br />
            {error.message}
        </Typography>
    );
};

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <Root></Root>,
            errorElement: <GenereicError />,
            children: [
                {
                    path: "used/",
                    element: <UsedList />,
                    loader: usedListLoader,
                    action: usedListAction,
                    errorElement: <GenereicError />,
                    children: [
                        {
                            path: ":id/",
                            loader: usedDialogLoader,
                            element: <UsedDialog />,
                            errorElement: <GenereicError />,
                            action: usedDialogAction,
                            children: [
                                {
                                    path: "requestQuoteSuccess/",
                                    errorElement: <GenereicError />,
                                    element: <UsedDialog />,
                                },
                            ],
                        },
                        {
                            path: "newsletter",
                            element: <Newsletter />,
                            errorElement: <GenereicError />,
                            action: NewsletterDialogAction,
                        },
                    ],
                },
                {
                    path: "new/",
                    element: <New />,
                    errorElement: <GenereicError />,
                },
                {
                    path: "showroom/",
                    element: <Showroom />,
                    errorElement: <GenereicError />,
                    action: showroomAction,
                },
                {
                    path: "new/chamberoven/",
                    errorElement: <GenereicError />,
                    element: <ChamberOven />,
                    action: chamberOvenAction,
                },
                {
                    path: "contact/",
                    errorElement: <GenereicError />,
                    element: <Contact />,
                },
                {
                    path: "user/",
                    element: <User />,
                    errorElement: <GenereicError />,
                    action: userAction,
                    loader: userLoader,
                },
                {
                    path: "used/:urlId/edit",
                    element: <AddUsed></AddUsed>,
                    errorElement: <GenereicError />,
                    loader: addUsedLoader,
                    action: addUsedAction,
                },
                {
                    path: "page/:name",
                    errorElement: <GenereicError />,
                    lazy: async () => {
                        const { default: Component, loader } = await import("./components/pdfPage/PdfPage");
                        return { Component, loader };
                    },
                },
            ],
        },
        {
            path: "login/",
            errorElement: <GenereicError />,
            element: <Login />,
            action: loginAction,
        },
        {
            path: "*",
            element: <Box>404</Box>,
            errorElement: <GenereicError />,
        },
    ],
    {
        future: {
            // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
            v7_normalizeFormMethod: true,
        },
    }
);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <AlertHandler>
                <CookieHandler>
                    <AccountManager>
                        <RouterProvider router={router} />
                    </AccountManager>
                </CookieHandler>
            </AlertHandler>
        </ThemeProvider>
    );
}

export default App;
