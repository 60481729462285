import Route from "./Route";

export default class User extends Route {
    constructor(path) {
        super();
        this.path = `/api/v1/${path}`;
    }

    login({ formData }) {
        return this.fetch(`${this.path}`, { method: "POST", body: formData });
    }

    add({ formData }) {
        return this.fetch(`${this.path}/user`, { method: "POST", body: formData });
    }

    getUser() {
        return this.fetch(`${this.path}/user`, { method: "GET" });
    }

    getAll() {
        return this.fetch(`${this.path}/users`, { method: "GET" });
    }
}
