import Chip from "@mui/material/Chip";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import TranslatedTypography from "../../Language/TranslatedTypography";

export default function SubChoices({ suboptions, isSelected, currentValues, onChange, ...props }) {
    const [selectedSubChoice, setSelectedSubChoice] = useState(suboptions?.default ?? "");

    return (
        <Grid2 container direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ width: "100%" }}>
            {isSelected &&
                suboptions &&
                suboptions.options.map((suboption, index) => {
                    const subIsSelected = selectedSubChoice === suboption.value;

                    return (
                        <Grid2 xs={12 / suboptions.options.length}>
                            <Chip
                                onClick={(event) => {
                                    setSelectedSubChoice(suboption.value);
                                    onChange(suboptions.name, suboption.value);
                                    event.stopPropagation();
                                }}
                                sx={{
                                    width: "100%",
                                    marginTop: 1,
                                    boxShadow: 3,
                                    "&.Mui-focusVisible": {
                                        zIndex: 1,
                                        backgroundColor: "offwhite.main",
                                    },
                                    "&:hover": {
                                        backgroundColor: subIsSelected ? "primary.superlight" : "white.main",
                                        ".MuiChip-label": {
                                            textDecoration: "underline",
                                        },
                                    },
                                    backgroundColor: subIsSelected ? "primary.superlight" : "white.main",
                                }}
                                label={<TranslatedTypography>{suboption.title}</TranslatedTypography>}
                            ></Chip>
                        </Grid2>
                    );
                })}
        </Grid2>
    );
}

export const prettifyOptions = (definition, option, values) => {
    const suboptionValue = values[option.suboption.name];
    //Get suboptionDefintion
    let suboptionDef;
    for (const suboption of option.suboption.options) {
        if (suboption.value === suboptionValue) {
            suboptionDef = suboption;
            break;
        }
    }

    return [
        { title: definition.title, value: option.prettyValue ?? option.value },
        { title: option.suboption.title, value: suboptionDef.prettyValue ?? suboptionDef.value },
    ];
};
