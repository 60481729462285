import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TranslatedTypography from "../Language/TranslatedTypography";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";

import { _API } from "../../App";
import useTheme from "@mui/material/styles/useTheme";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import RequestQuoteDialog, { action as requestQuoteDialogAction } from "./RequestQuoteDialog";
import MediaCarosel from "../mediaCarosel/MediaCarosel";
import FormattedText from "../formattedText/FormattedText";
import { AlertContext } from "../AlertHandler/AlertHandler";
import copy from "copy-to-clipboard";
import Image from "../Image";

export default function ProductDialog({ id, manualProduct, onClose, open, ...props }) {
    const loaderProduct = useLoaderData();
    const theme = useTheme();
    const breakPointMD = useMediaQuery(theme.breakpoints.up("md"));
    const breakPointSM = useMediaQuery(theme.breakpoints.up("sm"));
    const [requestQuote, setRequestQuote] = useState(false);
    const navigate = useNavigate();
    const { push } = useContext(AlertContext);

    const [product, setProduct] = useState();

    useEffect(() => {
        const getYoutubeCodeFromLink = (youtubeLink) => {
            try {
                const url = new URL(youtubeLink);
                if (url.searchParams.get("v")) return url.searchParams.get("v");

                return url.pathname.replace("/", "");
            } catch (error) {
                return youtubeLink;
            }
        };

        let _product = loaderProduct?.product?.body;
        if (manualProduct) _product = manualProduct;

        if (!_product || !_product.images) return;

        _product["media"] = _product.images.map((image) => {
            const _image = { ...image };
            _image["type"] = "image";
            _image["path"] = `/uploads/${image.path}`;
            return _image;
        });

        if (_product.youtube) {
            //extract video code from link
            //Remove all link-related text
            const sanitizedYoutube = getYoutubeCodeFromLink(_product.youtube);
            _product.media.push({ type: "embeddedYoutube", code: sanitizedYoutube, original: _product.youtube });
        }
        setProduct(_product);
    }, [loaderProduct, manualProduct]);

    const handleClose = () => {
        if (onClose) return onClose();
        navigate("/used");
    };

    const copyShareData = (shareData) => {
        const copyString = `${shareData.text}, ${shareData.url}`;
        copy(copyString);
        push({ severity: "info", message: `Kopierat länk` });
    };

    const triggerShare = (shareData) => {
        return navigator.share(shareData).catch((error) => {
            console.log(error);
            return copyShareData(shareData);
        });
    };

    const handleShare = async () => {
        const shareData = {
            title: product.titel,
            text: product.titel,
            url: `${window.location.origin}/used/${product.url}`,
        };
        try {
            return await triggerShare(shareData);
        } catch (error) {
            console.log(error);
            return copyShareData(shareData);
        }
    };

    return (
        <>
            <Dialog
                maxWidth="md"
                fullScreen={!breakPointMD}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") handleClose();
                }}
                open={open ?? true}
            >
                <DialogTitle>
                    <TranslatedTypography variant="h3" sx={{ fontSize: { xs: "1.5em", sm: "2em", lg: "3em" } }}>
                        {product?.titel}
                    </TranslatedTypography>
                </DialogTitle>
                <DialogContent sx={{ padding: breakPointSM ? 2 : 0 }}>
                    {product ? (
                        <>
                            <Box width={breakPointSM ? "80%" : "95%"} sx={{ margin: "0 auto 0 auto" }}>
                                <MediaCarosel medias={product.media} autoPlay={false} />

                                <Stack direction="column" alignItems="flex-start">
                                    <br />
                                    <Divider flexItem></Divider>
                                    <br />
                                    <TranslatedTypography variant={breakPointSM ? "h4" : "h5"}>
                                        Produktinformation
                                    </TranslatedTypography>
                                    <FormattedText
                                        value={product?.beskrivning}
                                        readOnly={true}
                                        theme={false}
                                        styles={{ ".ql-editor": { padding: 0 } }}
                                    />
                                    <br></br>
                                    <TranslatedTypography>
                                        Ovanstående information är upprättad efter bästa förmåga - men är inte bindande
                                        i detalj
                                    </TranslatedTypography>
                                    <Box
                                        sx={{
                                            marginTop: 2,
                                            padding: 2,
                                            borderRadius: 2,
                                            boxShadow: 2,
                                            borderTop: "8px solid darkred",
                                            borderTopRightRadius: 0,
                                            borderTopLeftRadius: 0,
                                        }}
                                    >
                                        <Stack>
                                            <Stack
                                                direction={breakPointSM ? "row" : "column-reverse"}
                                                justifyContent="space-between"
                                                alignItems="flex-start"
                                            >
                                                <TranslatedTypography
                                                    doTranslation={false}
                                                    variant={breakPointSM ? "h5" : "h6"}
                                                >
                                                    <b>HYBE Industrial ovens AB</b>
                                                </TranslatedTypography>
                                                <Stack direction="column" width="140px" justifyContent="stretch">
                                                    <Image width="100%" src="/public/hybe.png" />
                                                    <TranslatedTypography
                                                        doTranslation={false}
                                                        variant="p"
                                                        sx={{ fontSize: "15px" }}
                                                    >
                                                        <b>industrial ovens AB</b>
                                                    </TranslatedTypography>
                                                </Stack>
                                            </Stack>
                                            <TranslatedTypography variant="p" align="left">
                                                Vi är störst i Norden på begagnade industriugnar. Vi sätter alltid
                                                kunden i centrum med bra och snabb service.
                                                <br />
                                                <br /> Hos oss hittar du både nya och begagnade ugnar till Sveriges
                                                bästa priser. <br></br>Vi Säljer, Köper, förmedlar alla typer av
                                                industriugnar. <br />
                                                <br />
                                                Välkomna med er förfrågan
                                            </TranslatedTypography>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>
                        </>
                    ) : (
                        <CircularProgress color="darkred"></CircularProgress>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="triadicGreen"
                        variant="contained"
                        onClick={() => {
                            setRequestQuote(true);
                        }}
                    >
                        <TranslatedTypography color="white.main">Begär offert</TranslatedTypography>
                    </Button>
                    <Button onClick={handleShare} color="triadicGreen" variant="contained">
                        <TranslatedTypography color="white.main">Dela</TranslatedTypography>
                    </Button>

                    <Button color="offblack" variant="outlined" onClick={handleClose}>
                        <TranslatedTypography color="offblack.main">Stäng</TranslatedTypography>
                    </Button>
                </DialogActions>
            </Dialog>
            <RequestQuoteDialog
                title={product?.title}
                open={requestQuote}
                onClose={() => setRequestQuote(false)}
                fullScreen={!breakPointSM}
            ></RequestQuoteDialog>
        </>
    );
}

export const loader = async ({ params }) => {
    const { id } = params;
    return { product: await _API.used.getUrl({ id }) };
};

export const action = async (input) => {
    const formData = await input.request.formData();
    const intent = formData.get("intent");
    if (intent === "requestQuote") return await requestQuoteDialogAction({ ...input, formData });
    throw new Error("Invalid intent");
};
