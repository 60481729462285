import IconButton from "@mui/material/IconButton";
import TranslatedTypography from "../../Language/TranslatedTypography";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";

export default function SubChoices({ suboptions, isSelected, currentValues, onChange, ...props }) {
    const [number, setNumber] = useState(suboptions?.default ?? 1);

    const add = (event) => {
        if (suboptions.max !== undefined && number === suboptions.max) return;
        const newNumber = number + 1;
        setNumber(newNumber);
        onChange(suboptions.name, newNumber);
        event.stopPropagation();
    };

    const sub = (event) => {
        if (suboptions.min !== undefined && number === suboptions.min) return;
        const newNumber = number - 1;
        setNumber(newNumber);
        onChange(suboptions.name, newNumber);
        event.stopPropagation();
    };

    return (
        <>
            {isSelected && (
                <Grid2
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    sx={{ width: "100%" }}
                >
                    {suboptions.title && <TranslatedTypography>{suboptions.title}</TranslatedTypography>}
                    <IconButton onClick={sub}>
                        <RemoveIcon color="offblack" />
                    </IconButton>
                    <TranslatedTypography doTranslation={false}>{number}</TranslatedTypography>
                    <IconButton onClick={add}>
                        <AddIcon color="offblack" />
                    </IconButton>
                </Grid2>
            )}
        </>
    );
}

export const prettifyNumbers = (definition, option, values) => {
    const suboptionValue = values[option.suboption.name];

    return [
        { title: definition.title, value: option.prettyValue ?? option.value },
        { title: option.suboption.title, value: suboptionValue },
    ];
};
