import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { _API } from "../../App";
import SchemaForm from "../SchemaForm/SchemaForm";
import { AlertContext } from "../AlertHandler/AlertHandler";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const schema = [
    {
        label: "Email adress",
        name: "email",
        required: true,
        type: "email",
    },
    {
        label: "Lösenord",
        name: "password",
        required: true,
        type: "password",
        min: 8,
    },
];

export default function Login(props) {
    const alertHandler = useContext(AlertContext);
    const navigate = useNavigate();

    const onSuccess = () => {
        alertHandler.push({ severity: "success", message: "Inloggning lyckades" });
        navigate("/");
    };
    const onFail = () => {
        alertHandler.push({ severity: "warning", message: "Fel mejladress eller lösenord" });
    };

    return (
        <Box sx={{ backgroundColor: "white.main", padding: 2, boxShadow: 3, borderRadius: "5px", marginTop: 2 }}>
            <Stack justifyContent="flex-start" spacing={2}>
                <SchemaForm onSuccess={onSuccess} onRequestFailure={onFail} id="login-form" schema={schema} />
                <Button variant="contained" color="triadicGreen" type="submit" form="login-form">
                    Logga in
                </Button>
            </Stack>
        </Box>
    );
}

export const action = async ({ params, request }) => {
    const formData = await request.formData();
    return await _API.user.login({ formData });
};
