import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/Stack";
import Stack from "@mui/material/Stack";
import { useEffect, useRef, useState, createContext, useCallback } from "react";

const TIMER_FREQ = 200;
const ALERT_TIMEOUT = 5000;

export const AlertContext = createContext({
    push: () => {},
    clear: () => {},
    remove: () => {},
    alerts: [],
});

function AlertHandler({ children, open, timeout, onClose, ...props }) {
    const [alerts, setAlerts] = useState([]);
    const timeoutRef = useRef(null);

    const checkAlertTimeout = useCallback(() => {
        if (alerts.length > 0) {
            const _alerts = [...alerts];
            for (let i = 0; i < alerts.length; i++) {
                const alert = alerts[i];
                if (Date.now() - alert.time > ALERT_TIMEOUT) _alerts.splice(i, 1);
            }
            setAlerts(_alerts);
        }
        timeoutRef.current = setTimeout(checkAlertTimeout, TIMER_FREQ);
    }, [alerts]);

    useEffect(() => {
        if (!timeoutRef.current) timeoutRef.current = setTimeout(checkAlertTimeout, TIMER_FREQ);

        return () => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        };
    }, [checkAlertTimeout]);

    const push = ({ message, severity }) => {
        const id = Math.floor(Math.random() * 100);
        setAlerts([{ time: Date.now(), message, severity, id }, ...alerts]);
        return id;
    };

    const clear = () => {
        setAlerts([]);
    };

    const remove = (alertId) => {
        const index = alerts.findIndex((alert) => alert.id === alertId);
        const _alerts = [...alerts];
        _alerts.splice(index, 1);
        setAlerts(_alerts);
    };

    const CircularAlertIcon = ({ time }) => {
        return (
            <CircularProgress
                color="white"
                size={20}
                variant="determinate"
                value={((Date.now() - time) / ALERT_TIMEOUT) * 100}
            />
        );
    };

    return (
        <>
            <div
                style={{
                    position: "fixed",
                    width: "80%",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    bottom: 20,
                    zIndex: "10000",
                }}
            >
                <Stack direction="column" spacing={1}>
                    {alerts.map((alert) => (
                        <Alert
                            iconMapping={{
                                error: <CircularAlertIcon time={alert.time} />,
                                info: <CircularAlertIcon time={alert.time} />,
                                success: <CircularAlertIcon time={alert.time} />,
                                warning: <CircularAlertIcon time={alert.time} />,
                            }}
                            fullWidth
                            onClose={() => remove(alert.id)}
                            variant="filled"
                            severity={alert.severity}
                        >
                            <Stack direction="row" spacing={3} justifyContent="space-between" alignContent="center">
                                {alert.message}
                            </Stack>
                        </Alert>
                    ))}
                </Stack>
            </div>
            <AlertContext.Provider
                value={{
                    alerts,
                    remove: remove,
                    push: push,
                    clear: clear,
                }}
            >
                {children}
            </AlertContext.Provider>
        </>
    );
}

export default AlertHandler;
