import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import { useEffect, useState } from "react";

export default function ControlledTextField({
    name,
    error,
    label,
    condition = true,
    onChange,
    controlProps,
    type,
    required,
    value,
    defaultValue,
    ...props
}) {
    const [filteredType, setFilteredType] = useState(type);

    useEffect(() => {
        const getType = () => {
            if (["number"].includes(type)) return "number";
            if (["phone"].includes(type)) return "phone";
            if (["password"].includes(type)) return "password";
            return "text";
        };

        setFilteredType(getType(type));
    }, [type]);

    return (
        <>
            {condition && (
                <FormControl {...controlProps}>
                    <InputLabel htmlFor={name}>{label}</InputLabel>
                    <OutlinedInput
                        error={error ? true : false}
                        helperText={error}
                        defaultValue={value}
                        {...props}
                        id={name}
                        name={name}
                        label={label}
                        type={filteredType}
                        onChange={(event) => onChange(event.currentTarget.value)}
                    />
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            )}
        </>
    );
}
