import { useEffect } from "react";
import Header from "./components/header/Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Root = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === "/") navigate("/contact");
    }, [location, navigate]);

    return (
        <Header>
            <Outlet />
        </Header>
    );
};

export default Root;
