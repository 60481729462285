import Route from "./Route";

export default class ShowRoom extends Route {
    constructor(path) {
        super();
        this.path = `/api/v1/${path}`;
    }

    requestVisit({ formData }) {
        return this.fetch(`${this.path}/requestVisit`, { method: "POST", body: formData });
    }
}
