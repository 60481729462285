import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "@mui/material/styles/styled";
import { useEffect, useState } from "react";
import { useContentWidth } from "../../hooks/useContentWidth";

const Img = styled("img")``; // Heads up, dont remove the empty string after this statement. Everything breaks.

export default function FullWidthImages({ images, imgProps = {}, ...props }) {
    const [filteredImages, setFilteredImages] = useState(images);

    const xsBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("xs"));
    const smBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const mdBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("md"));
    const lgBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("lg"));
    const xlBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("xl"));

    useEffect(() => {
        if (!images) return;

        const breakpointMap = {
            xs: xsBreakpoint,
            sm: smBreakpoint,
            md: mdBreakpoint,
            lg: lgBreakpoint,
            xl: xlBreakpoint,
        };

        const _filteredImages = images.filter((image) => {
            if (image.breakpoint === undefined) return true;
            return breakpointMap[image.breakpoint];
        });
        setFilteredImages(_filteredImages);
    }, [xsBreakpoint, smBreakpoint, mdBreakpoint, lgBreakpoint, xlBreakpoint, images]);

    const width = useContentWidth();

    const [calculatedHeight, setCalculatedHeight] = useState();

    useEffect(() => {
        const calcImgRatio = (img) => img.width / img.height;

        let totalRatio = 0;
        for (let i = 0; i < filteredImages.length; i++) {
            totalRatio += calcImgRatio(filteredImages[i]);
        }
        setCalculatedHeight(width / totalRatio);
    }, [width, filteredImages]);

    return (
        <Box>
            <Stack
                direction="row"
                spacing={0}
                sx={{
                    padding: 0,
                }}
            >
                {filteredImages.map((image) => (
                    <Img
                        sx={{
                            objectFit: "cover",
                            objectposition: "left",
                            height: `${calculatedHeight}px`,
                        }}
                        src={image.path}
                    />
                ))}
            </Stack>
        </Box>
    );
}
