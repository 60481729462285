import Box from "@mui/material/Box";
import MuiSkeleton from "@mui/material/Skeleton";
import styled from "@mui/material/styles/styled";
import { useEffect, useState } from "react";

const Img = styled("img")``; // Heads up, dont remove the empty string after this statement. Everything breaks.

export const Skeleton = ({ ...props }) => {
    const [skeletonHeight, setSkeletonHeight] = useState(false);
    const [skeletonWidth, setSkeletonWidth] = useState(false);

    useEffect(() => {
        if (props?.sx?.height) return setSkeletonHeight(props.sx.height);
        if (props?.height) return setSkeletonHeight(props.height);
    }, [props?.height, props?.sx?.height]);

    useEffect(() => {
        if (props?.sx?.width) return setSkeletonWidth(props.sx.width);
        if (props?.width) return setSkeletonWidth(props.width);
    }, [props?.width, props?.sx?.width]);

    return <MuiSkeleton height={skeletonHeight ?? "100%"} width={skeletonWidth ?? "100%"} {...props}></MuiSkeleton>;
};

export default function Image({ disableSkeleton = false, ...props }) {
    const [loading, setLoading] = useState(true);

    const handleOnLoad = (event) => {
        setLoading(false);
        if (props?.onLoad) props.onLoad(event);
    };

    return (
        <>
            <Box sx={{ display: loading ? "none" : "inline" }}>
                <Img onLoad={handleOnLoad} {...props}></Img>
            </Box>
            {loading && !disableSkeleton && <Skeleton {...props} />}
        </>
    );
}
