import { useEffect, useState, createContext } from "react";
import { _API } from "../../App";

const defaultUser = {
    email: null,
    admin: false,
    superadmin: false,
    name: null,
    id: null,
};

export const UserContext = createContext(defaultUser);

function AlertHandler({ children, open, timeout, onClose, ...props }) {
    const [user, setUser] = useState(defaultUser);

    useEffect(() => {
        const asyncWrapper = async () => {
            const {body} = await _API.user.getUser();
            if (!body) return;
            setUser(body);
        };

        asyncWrapper();
    }, []);

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export default AlertHandler;
