import { useContext, useState, useEffect, useCallback } from "react";
import { SchemaFormContext, parseJsonCondition } from "./SchemaForm";
import ControlledHidden from "./ControlledHidden";
import ControlledImages from "./ControlledImages";
import ControlledButtonGroup from "./ControlledButtonGroup";
import ControlledTextField from "./ControlledTextField";
import ControlledCheckbox from "./ControlledCheckbox";
import ControlledFile from "./ControlledFile";
import ControlledFormattedTextField from "./ControlledFormattableTextField";
import useLanguage from "../../hooks/useLanguage";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";

export default function ControlledField({ entry, onChange, ...props }) {
    const [parsedCondition, setParsedContidition] = useState(true);
    const { values } = useContext(SchemaFormContext);
    const [fieldValue, setFieldValue] = useState(values[entry?.name]);

    const [translatedLabel, setTranslatedLabel] = useState(entry?.label);
    // eslint-disable-next-line no-unused-vars
    const [_, translate] = useLanguage();

    useEffect(() => {
        setFieldValue(values[entry.name]);
    }, [values, entry?.name]);

    const getFieldType = (type) => {
        if (type === "hidden") return ControlledHidden;
        if (type === "toggleGroup") return ControlledButtonGroup;
        if (type === "image") return ControlledImages;
        if (type === "checkbox") return ControlledCheckbox;
        if (type === "file") return ControlledFile;
        if (type === "formattedText") return ControlledFormattedTextField;
        return ControlledTextField;
    };

    useEffect(() => {
        if (entry.condition) {
            const newState = parseJsonCondition(entry.condition, values);
            setParsedContidition(newState);
        }
    }, [entry.condition, values]);

    useAsyncEffect(async () => {
        if (!entry?.label) return;
        const _translated = await translate(entry.label);
        setTranslatedLabel(_translated);
    }, [translate, entry?.label]);

    const onValueChange = useCallback(
        (value) => {
            onChange(entry.name, value);
        },
        [entry.name, onChange]
    );

    const Field = getFieldType(entry.type);
    return (
        <Field
            {...props}
            {...entry}
            label={translatedLabel}
            condition={parsedCondition}
            value={fieldValue}
            onChange={onValueChange}
        />
    );
}
