import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import TranslatedTypography from "../../Language/TranslatedTypography";
import styled from "@mui/material/styles/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import jsonLogic from "json-logic-js";
import SubChoices, { prettifySuboption } from "../Subchoices/Subchoices";

import { useEffect, useState } from "react";

const Img = styled("img")``;

const Option = ({ isSelected, onClick, onChange, definition, option, currentValues }) => {
    const [tooltip, setTooltip] = useState(option.tooltip ?? option.title);
    const [brokenRules, setBrokenRules] = useState([]);

    const smBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));

    const height = definition.dense ? 80 : 150;

    useEffect(() => {
        if (!option.rules) return;
        const rulesBroken = option.rules.filter((rule) => !jsonLogic.apply(rule.condition, currentValues));
        setBrokenRules(rulesBroken);
        if (rulesBroken.length > 0) {
            if (isSelected) onClick(definition.name, definition.default);
            setTooltip(rulesBroken.map((rule) => rule.desc).join(", "));
        } else {
            setTooltip(option.tooltip ?? option.title);
        }
    }, [
        currentValues,
        option?.rules,
        option?.tooltip,
        option?.title,
        definition?.default,
        onClick,
        isSelected,
        definition?.name,
    ]);

    return (
        <Grid2 {...(smBreakpoint ? { xs: 12 / definition.options.length } : { xs: 12 })}>
            <ButtonBase
                disabled={brokenRules.length !== 0}
                sx={{
                    width: "100%",
                    height: `${height}px`,
                    borderRadius: 3,
                    boxShadow: 3,
                    "&.Mui-focusVisible": {
                        zIndex: 1,
                        backgroundColor: "offwhite.main",
                    },
                    "&:hover": {
                        ".MuiTypography-h5": {
                            textDecoration: "underline",
                        },
                    },
                    backgroundColor: isSelected ? "primary.superlight" : "white.main",
                    "&.Mui-disabled": {
                        backgroundColor: "lightgray.main",
                    },
                }}
                onClick={() => onClick(definition.name, option.value)}
            >
                <Grid2
                    container
                    direction="column"
                    justifyContent={"center"}
                    alignItems="center"
                    sx={{ width: "100%", height: `${height}px` }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent={option.image ? "flex-start" : "center"}
                        spacing={2}
                        sx={{ width: "100%" }}
                    >
                        {option.Icon && <option.Icon color="offblack" />}
                        {option.image && (
                            <Img
                                sx={{ height: `${height}px`, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}
                                src={option.image}
                            />
                        )}
                        <Stack direction="column">
                            <TranslatedTypography variant="h5">{option.title}</TranslatedTypography>
                            <TranslatedTypography variant="subtitle2">{option.desc}</TranslatedTypography>
                        </Stack>
                    </Stack>
                    <Box position="absolute" sx={{ top: `${height}px`, width: "100%" }}>
                        <SubChoices
                            onChange={onChange}
                            currentValues={currentValues}
                            suboptions={option.suboption}
                            isSelected={isSelected}
                        />
                        {brokenRules?.length > 0 && <TranslatedTypography>{tooltip}</TranslatedTypography>}
                    </Box>
                </Grid2>
            </ButtonBase>
            <Box
                sx={{
                    height: option.suboption !== undefined && !smBreakpoint && isSelected ? "50px" : 0,
                }}
            ></Box>
            <Box
                sx={{
                    height: brokenRules?.length ? "30px" : 0,
                }}
            ></Box>
        </Grid2>
    );
};

export default function Options({ onChange, currentValues, definition, children, ...props }) {
    const [selected, setSelected] = useState(definition.default);

    const onClick = (name, value) => {
        setSelected(value);
        onChange(name, value);
    };

    return (
        <>
            <TranslatedTypography align="center">{definition.desc}</TranslatedTypography>
            <Grid2
                container
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                sx={{ marginTop: 0.5 }}
            >
                {definition.options.map((option) => (
                    <Option
                        onChange={onChange}
                        isSelected={selected === option.value}
                        onClick={onClick}
                        currentValues={currentValues}
                        option={option}
                        definition={definition}
                    ></Option>
                ))}
            </Grid2>
        </>
    );
}

export const prettifyOptions = (definition, values) => {
    for (const option of definition.options) {
        if (option.value === values[definition.name]) {
            if (!option.suboption) return [{ title: definition.title, value: option.prettyValue ?? option.value }];
            return prettifySuboption(definition, option, values);
        }
    }
    //TODO: HAndle suboptions
};
