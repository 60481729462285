import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function ControlledButtonGroup({ condition, value, name, defaultVal, label, onChange, ...props }) {
    if (!condition) return <></>;
    return (
        <>
            <FormControl>
                <FormControlLabel
                    {...props}
                    name={name}
                    control={
                        <Checkbox
                            defaultChecked={defaultVal === true}
                            onChange={(_, _bool) => {
                                onChange(_bool);
                            }}
                            value={value}
                        />
                    }
                    label={label}
                />
            </FormControl>
            <input type="hidden" value={value}></input>
        </>
    );
}
