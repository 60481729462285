import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import FormattedText from "../formattedText/FormattedText";
import { useState } from "react";

export default function ControlledFormattedTextField({
    name,
    error,
    label,
    condition = true,
    onChange,
    controlProps,
    type,
    required,
    value,
    ...props
}) {
    const [defaultValue] = useState(value);

    const handleChange = (value) => {
        onChange(value);
    };

    return (
        <>
            <FormControl>
                <Typography align="left">{label}</Typography>
                {condition && (
                    <FormattedText theme="snow" defaultValue={defaultValue} onChange={handleChange} readOnly={false} />
                )}
                {error && <FormHelperText>{error}</FormHelperText>}
                <input type="hidden" name={name} value={value} />
            </FormControl>
        </>
    );
}
