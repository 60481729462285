import Route from "./Route";

export default class Used extends Route {
    constructor(path) {
        super();
        this.path = `/api/v1/${path}`;
    }

    add({ formData }) {
        return this.fetch(`${this.path}`, { method: "POST", body: formData });
    }

    getAll() {
        return this.fetch(`${this.path}`);
    }

    getByName({ name }) {
        return this.fetch(`${this.path}/${name}`);
    }
}
