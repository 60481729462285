import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useContext, useState } from "react";

import SchemaForm from "../SchemaForm/SchemaForm";
import { _API } from "../../App";
import { AlertContext } from "../AlertHandler/AlertHandler";
import Image from "../Image";
import { useContentWidth } from "../../hooks/useContentWidth";

const schema = [
    {
        name: "type",
        type: "toggleGroup",
        defaultVal: "organization",
        members: [
            {
                label: "Organisation",
                name: "organization",
            },
            {
                label: "Privatperson",
                name: "private",
            },
        ],
    },
    {
        label: "Företagsnamn",
        name: "companyName",
        required: true,
        condition: { "==": [{ var: "type" }, "organization"] },
        type: "text",
    },
    {
        label: "Organisationsnummer",
        condition: { "==": [{ var: "type" }, "organization"] },
        name: "vat",
        required: true,
        type: "text",
    },
    {
        label: "Epost",
        name: "email",
        required: true,
        type: "email",
    },
    {
        label: "För- och efternamn",
        name: "fullname",
        required: true,
        type: "text",
    },
    {
        label: "Telefonnummer",
        name: "phone",
        required: true,
        type: "phone",
    },
    {
        label: "Meddelande (Vad ni är intresserade av och vad ni vill ha ut av visningen)",
        name: "message",
        type: "text",
        multiline: true,
        required: true,
    },
    {
        name: "intent",
        defaultVal: "bookShowroom",
        type: "hidden",
    },
];
export default function Showroom({ id, open, additionalSchemaEntries, children, ...props }) {
    const alertHandler = useContext(AlertContext);
    const [formQuoteData, setFormQuoteData] = useState();
    const width = useContentWidth();

    const onSuccess = () => {
        alertHandler.push({ severity: "success", message: "Visningsförfrågan skickad!" });
    };

    const onFail = () => {
        alertHandler.push({ severity: "error", message: "Misslyckades med visningsförfrågan, var snäll försök igen." });
    };

    return (
        <Box>
            {children}
            <Image width={width} src="/public/showroom/showroom1.jpg"></Image>
            <Typography variant="h2">Testa romers pulverlacksanläggning</Typography>
            <Typography variant="p">
                Fyll i informationen nedan så hör vi av oss angående bokning av tid. Vi har begränsat med platser och vi
                förbihåller oss rätten att fördela platserna.
            </Typography>
            <SchemaForm
                onChange={setFormQuoteData}
                defaultValues={formQuoteData}
                onSuccess={onSuccess}
                onRequestFail={onFail}
                id="request-showroom-visit"
                schema={schema}
            />

            <Button variant="contained" color="triadicGreen" type="submit" form="request-showroom-visit">
                Skicka
            </Button>
        </Box>
    );
}

export const action = async (input) => {
    const formData = await input.request.formData();
    const res = await _API.showroom.requestVisit({ formData });
    if (!res.ok) throw res;
    return res;
};
