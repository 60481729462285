import { useCallback, useEffect, useRef, useState } from "react";
import Pica from "pica";

export default function ImageUploader({ setLoading, multiple, name, onChange, images, ...props }) {
    const fileInputRef = useRef(null);
    const [imageDefinitions, setImageDefinitions] = useState([]);

    useEffect(() => {
        setImageDefinitions(
            images?.map((image) => {
                const newImg = { ...image };
                if (newImg.new) delete newImg["path"];
                return newImg;
            })
        );
    }, [images]);

    const handleChange = useCallback(
        async (event) => {
            setLoading(true);
            const allFiles = event.target.files;
            const files = multiple ? allFiles : [allFiles[0]];

            const _images = [];
            for (const file of files) {
                const id = `${Math.random().toString(16).slice(2)}_${file.name}`;
                const compressed = await handleRescaling(file, { downscaleWidth: 1000 });
                const thumbnail = await handleRescaling(file, { downscaleWidth: 200 });
                const timestamp = Date.now();
                _images.push({
                    file: compressed,
                    name: file.name,
                    type: "image/jpg",
                    id: id,
                    path: URL.createObjectURL(compressed),
                    timestamp,
                });
                _images.push({
                    custom: "thumbnail",
                    file: thumbnail,
                    name: `${file.name}_thumbnail`,
                    type: "image/jpg",
                    id: `${id}_thumbnail`,
                    path: URL.createObjectURL(thumbnail),
                    timestamp,
                });
            }
            onChange(_images);
            setLoading(false);
        },
        [onChange, multiple, setLoading]
    );

    const handleRescaling = async (image, { downscaleWidth = 1000 }) => {
        const bitmapImage = await createImageBitmap(image);

        // If width over "downscaleWith", downscale it. Otherwise keep original.
        const newWidth = bitmapImage.width > downscaleWidth ? downscaleWidth : bitmapImage.width;
        const scalingFactor = newWidth / bitmapImage.width;

        const resizedCanvas = document.createElement("canvas");

        resizedCanvas.height = bitmapImage.height * scalingFactor;
        resizedCanvas.width = newWidth;

        const pica = Pica();
        const picaResized = await pica.resize(bitmapImage, resizedCanvas, {
            unsharpAmount: 80,
            unsharpRadius: 0.6,
            unsharpThreshold: 2,
            transferable: true,
        });

        resizedCanvas.remove();

        const blob = await pica.toBlob(picaResized, "image/jpeg", 0.6);
        const backToFile = new File([blob], image.name, { type: "image/jpeg" });
        return backToFile;
    };

    return (
        <>
            <input
                {...props}
                ref={fileInputRef}
                multiple
                type="file"
                accept="image/png, image/jpeg, image/gif, image/webp"
                name={name}
                onChange={handleChange}
            ></input>
            <input type="hidden" value={JSON.stringify(imageDefinitions)} name={`${name}_definitions`}></input>
        </>
    );
}
