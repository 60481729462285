import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

import SchemaForm from "../SchemaForm/SchemaForm";
import { _API } from "../../App";
import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../AlertHandler/AlertHandler";
import { useLoaderData } from "react-router-dom";

const schema = [
    {
        label: "Namn",
        name: "name",
        required: true,
        type: "text",
        urlSafe: true,
        relative: true,
    },
    {
        label: "Titel",
        name: "title",
        type: "text",
    },
    {
        label: "Pdf",
        name: "pdfFile",
        required: true,
        multiple: false,
        type: "file",
        accept: "application/pdf",
    },
    {
        name: "intent",
        required: true,
        type: "hidden",
        defaultVal: "add_pdf",
    },
];

export default function AddUsed(props) {
    const [values, setValues] = useState({});
    const [pdfExists, setPdfExists] = useState("false");
    const alertHandler = useContext(AlertContext);
    const loaderData = useLoaderData();

    useEffect(() => {
        const asyncWrap = async () => {
            try {
                setPdfExists("loading");
                const res = await _API.pdf.getByName({ name: values.name });
                if (res) setPdfExists("true");
            } catch (error) {
                if (error.message.includes("404")) return setPdfExists("false");
                setPdfExists("error");
            }
        };
        if (values?.name) asyncWrap();
    }, [values?.name]);

    return (
        <>
            <Box sx={{ height: "30px" }}>
                {pdfExists === "true" && (
                    <Typography>Namnet finns redan och kommer därför skriva över nuvarande pdf istället.</Typography>
                )}
                {pdfExists === "false" && <Typography>Namnet finns inte och kommer därför skapa en ny.</Typography>}
                {pdfExists === "loading" && <CircularProgress></CircularProgress>}
                {pdfExists === "error" && <Typography>Något gick fel</Typography>}
            </Box>
            <SchemaForm
                onChange={setValues}
                onSuccess={() => {
                    alertHandler.push({ severity: "success", message: "Posten är nu uppladdad" });
                }}
                onRequestFail={() => {
                    alertHandler.push({ severity: "error", message: "Misslyckades med uppladdningen, försök igen." });
                }}
                id="create-new-pdf"
                schema={schema}
            />
            <Typography>
                OBS! Kör den genom:{" "}
                <a rel="noreferrer" target="_blank" href="https://www.ilovepdf.com/compress_pdf">
                    Komprimerare
                </a>{" "}
                innan uppladdning!
            </Typography>
            <Button
                sx={{ width: "100%", marginTop: 2 }}
                variant="contained"
                color="triadicGreen"
                type="submit"
                form="create-new-pdf"
            >
                Ladda upp ny
            </Button>

            <Box>
                <Typography variant="h3">Existerande:</Typography>
                <List>
                    {loaderData?.pdfs &&
                        loaderData.pdfs.map((pdf) => (
                            <ListItem sx={{ boxShadow: 2 }}>
                                <Stack direction="column">
                                    <Typography>
                                        <b>Namn: </b>
                                        {pdf.name}
                                    </Typography>
                                    <Typography>
                                        <b>Pdf: </b>
                                        <Link target="_blank" href={pdf.path}>
                                            {pdf.path}
                                        </Link>
                                    </Typography>
                                    <Typography>
                                        <b>Titel: </b>
                                        {pdf.title}
                                    </Typography>
                                    <Typography>
                                        <b>Page: </b>
                                        <Link target="_blank" href={`/page/${pdf.name}`}>
                                            {`/page/${pdf.name}`}
                                        </Link>
                                    </Typography>
                                </Stack>
                            </ListItem>
                        ))}
                </List>
            </Box>
        </>
    );
}

export const loader = () => {
    return _API.pdf.getAll();
};

export const action = async ({ formData, params, request }) => {
    return await _API.pdf.add({ formData });
};
