import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useContext } from "react";
import { UserContext } from "../accountManager/AccountManager";
import AddUsed, { action as addUsedAction, loader as addUsedLoader } from "./AddUsed";
import AddPdf, { action as addPdfAction, loader as addPdfLoader } from "./AddPdf";
import UserChanger, { action as userChangerAction, loader as userChangerLoader } from "./UserChanger";

export default function User(props) {
    const { email, name, admin, superadmin } = useContext(UserContext);

    return (
        <Stack>
            <Typography variant="h3">
                Inloggad som {name}({email})
            </Typography>
            {(admin || superadmin) && (
                <>
                    <Box sx={{ borderRadius: 3, margin: 4, padding: 2, backgroundColor: "white.main" }}>
                        <Box>
                            <Typography align="left" variant="h4">
                                Ladda upp ny begagnad produkt:
                            </Typography>
                            <AddUsed />
                        </Box>
                    </Box>
                    <Box sx={{ borderRadius: 3, margin: 4, padding: 2, backgroundColor: "white.main" }}>
                        <Box>
                            <Typography align="left" variant="h4">
                                Ladda upp ny/redigera pdf:
                            </Typography>
                            <AddPdf />
                        </Box>
                    </Box>
                </>
            )}
            {superadmin && <UserChanger />}
        </Stack>
    );
}

export const loader = async (input) => {
    const [used, pdfs, userChanger] = await Promise.all([
        addUsedLoader(input),
        addPdfLoader(input).then((res) => res.body),
        userChangerLoader(input)
            .then((res) => res.body)
            .catch((error) => null),
    ]);
    return { used, pdfs, userChanger };
};

export const action = async (input) => {
    const formData = await input.request.formData();
    const intent = formData.get("intent");
    if (intent === "add_used") return await addUsedAction({ ...input, formData });
    if (intent === "add_pdf") return await addPdfAction({ ...input, formData });
    if (intent === "add_user") return await userChangerAction({ ...input, formData });
    throw new Error("Invalid intent");
};
