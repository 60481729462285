import { Form, useActionData } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import { useState, useEffect, useCallback, useRef, createContext } from "react";
import jsonLogic from "json-logic-js";
import ControlledField from "./ControlledField";
import { useSubmit } from "react-router-dom";
import SchemaValidator from "./SchemaValidator";

export const SchemaFormContext = createContext({
    form: null,
    values: {},
});

export const parseJsonCondition = (condition, values) => {
    return jsonLogic.apply(condition, values);
};

export default function SchemaForm({
    schema,
    children,
    onSuccess,
    onChange,
    onRequestFailure,
    onValidationError,
    defaultValues,
    ...props
}) {
    const [loading, setLoading] = useState(false);
    const submit = useSubmit();
    const actionData = useActionData();
    const [currActionData, setCurrActionData] = useState(actionData ?? { __id: "-1" });
    const formRef = useRef();

    const [validationErrors, setValidationErrors] = useState([]);

    const getDefaultValues = useCallback(
        (_schema) => {
            const getRowDefaultValue = (entry) => {
                if (defaultValues && defaultValues[entry.name]) return defaultValues[entry.name];
                if (entry.value) return entry.value;
                if (entry.defaultVal) return entry.defaultVal;
                if (entry.default) return entry.default;
                return null;
            };

            const _values = {};
            for (const entry of _schema) {
                _values[entry.name] = getRowDefaultValue(entry);
            }
            return _values;
        },
        [defaultValues]
    );

    useEffect(() => {
        if (!(actionData?.__id && currActionData?.__id)) return;
        if (actionData.__id === currActionData.__id) return;
        setCurrActionData(actionData);
        setLoading(false);
        if (actionData?.ok) {
            setValues(getDefaultValues(schema));
            formRef.current.reset();
            return onSuccess(actionData);
        }
    }, [actionData, onSuccess, currActionData, schema, formRef, getDefaultValues]);

    const [values, setValues] = useState(() => getDefaultValues(schema));

    const controlledFieldOnChange = useCallback(
        (name, value) => {
            const _values = { ...values };
            _values[name] = value;
            setValues(_values);
            if (onChange) onChange(_values);
        },
        [values, onChange]
    );

    const onSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.target);

        for (const formdataKey of formData.keys()) {
            const schemaDef = schema.find((schemaEntry) => schemaEntry.name === formdataKey);
            if (!schemaDef) continue;

            const formdataValue = values[formdataKey];
            if (formdataValue?.constructor === {}.constructor) {
                // Value is an json object
                formData.delete(formdataKey);
                formData.append(formdataKey, JSON.stringify(formdataValue));
            }

            if (schemaDef.type === "image") {
                formData.delete(formdataKey);
                if (!values[formdataKey]) continue;
                for (const fileValue of values[formdataKey]) {
                    if (!fileValue.new) continue; //Only upload new photos, not old ones
                    formData.append(formdataKey, fileValue.file, fileValue.name, { type: fileValue.type });
                }
            }
        }
        const schemaValidtion = await validateSchema(schema, formData);

        if (schemaValidtion.error) {
            setValidationErrors(schemaValidtion.error);
            setLoading(false);
            return;
        }
        setValidationErrors([]);
        submit(formData, { method: "post", encType: "multipart/form-data" });
    };

    return (
        <SchemaFormContext.Provider value={{ form: formRef, values: values }}>
            <Form method="post" onSubmit={onSubmit} ref={formRef} {...props}>
                <Stack spacing={1} sx={{ marginTop: 1 }}>
                    {schema.map((entry, index) => {
                        const error = validationErrors.find((err) => err.context.key === entry.name)?.message;

                        return (
                            <ControlledField
                                key={index}
                                entry={entry}
                                error={error}
                                onChange={controlledFieldOnChange}
                                values={values}
                            />
                        );
                    })}
                    {children}
                </Stack>
            </Form>
            {loading === true && <LinearProgress color="triadicGreen"></LinearProgress>}
        </SchemaFormContext.Provider>
    );
}

export const validateSchema = async (schema, formData) => {
    const formJSON = {};
    for (const [key, value] of [...formData.entries()]) {
        if (value !== "") formJSON[key] = value;
    }

    const validator = new SchemaValidator(schema);
    const validated = await validator.validate(formJSON);

    return { error: validated?.error?.details };
};
