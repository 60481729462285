import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useEffect, useState, createContext } from "react";
import { useCookies } from "react-cookie";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import TranslatedTypography from "../Language/TranslatedTypography";

export const CookieContext = createContext({});

function CookieHandler({ children, open, timeout, onClose, ...props }) {
    const [cookieRequestOpen, setCookieRequestOpen] = useState(false);

    const [cookies, setCookies] = useCookies();

    useEffect(() => {
        if (!cookies) return;
        if ([true, "true", "1", 1].includes(cookies["acceptedCookies"])) return setCookieRequestOpen(false);

        setCookieRequestOpen(true);
    }, [cookies]);

    const onCookiesAccepted = () => {
        setCookies("acceptedCookies", true, { path: "/" });
        setCookieRequestOpen(false);
    };

    return (
        <>
            <TrapFocus open disableAutoFocus disableEnforceFocus>
                <Fade appear={true} in={cookieRequestOpen}>
                    <Paper
                        role="dialog"
                        aria-modal="false"
                        aria-label="Cookie banner"
                        square
                        variant="outlined"
                        tabIndex={-1}
                        sx={{
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            m: 0,
                            p: 2,
                            borderWidth: 0,
                            borderTopWidth: 1,
                            zIndex: 2000,
                        }}
                    >
                        <Stack
                            direction={{ sm: "column", md: "row" }}
                            alignItems="center"
                            justifyContent="center"
                            gap={2}
                        >
                            <Box
                                sx={{
                                    flexShrink: 1,
                                    alignSelf: "center",
                                }}
                            >
                                <TranslatedTypography fontWeight="bold">
                                    Den här hemsidan använder cookies
                                </TranslatedTypography>
                                <TranslatedTypography variant="body2">
                                    *Industriugnar.com använder enbart funktionella cookies.
                                </TranslatedTypography>
                                <TranslatedTypography variant="body2">
                                    *Industriugnar.com använder inga cookies för tracking.
                                </TranslatedTypography>
                                <TranslatedTypography variant="body2">
                                    *Industriugnar.com delar ej cookies med tredje-part.
                                </TranslatedTypography>
                            </Box>

                            <Button size="small" onClick={onCookiesAccepted} variant="contained">
                                <TranslatedTypography color="white.main">
                                    Tillåt funktionella cookies
                                </TranslatedTypography>
                            </Button>
                        </Stack>
                    </Paper>
                </Fade>
            </TrapFocus>
            <CookieContext.Provider value={{}}>{children}</CookieContext.Provider>
        </>
    );
}

export default CookieHandler;
