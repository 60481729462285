import { useState, useEffect, useRef } from "react";

export function useBoxWidth(additional) {
    const ref = useRef();
    const [contentWidth, setContentWidth] = useState(0);
    useEffect(() => {
        function handleResize() {
            setContentWidth(ref.current?.clientWidth ?? 0);
        }

        const resizeObserver = new ResizeObserver(handleResize);

        if (ref.current) resizeObserver.observe(ref.current);
        handleResize();
        return () => resizeObserver.disconnect();
    }, [ref, additional]);
    return { ref: ref, width: contentWidth };
}
