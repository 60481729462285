import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import Button from "@mui/material/Button";

import SchemaForm from "../SchemaForm/SchemaForm";
import { _API } from "../../App";
import { useContext, useEffect, useState } from "react";
import UsedRow from "../used/UsedRow";
import UsedDialog from "../usedDialog/UsedDialog";
import { AlertContext } from "../AlertHandler/AlertHandler";
import { useLoaderData } from "react-router-dom";

const schema = [
    {
        label: "Titel(Svenska)",
        name: "titel",
        required: true,
        type: "text",
    },
    {
        label: "Beskrivning(Svenska)",
        name: "beskrivning",
        required: true,
        type: "formattedText",
    },
    {
        name: "category",
        type: "toggleGroup",
        defaultVal: "oven",
        members: [
            {
                label: "Ugn",
                name: "oven",
            },
            {
                label: "Maskin",
                name: "machine",
            },
        ],
    },
    {
        label: "Youtube",
        name: "youtube",
        required: false,
        type: "text",
    },
    {
        label: "Bilder",
        name: "images",
        type: "image",
        allowOrdering: true,
        multiple: true,
    },
    {
        name: "intent",
        required: true,
        type: "hidden",
        defaultVal: "add_used",
    },
];

export default function AddUsed(props) {
    const [values, setValues] = useState({});
    const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
    const alertHandler = useContext(AlertContext);

    const data = useLoaderData();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [defaultValues, setDefaultValues] = useState();

    useEffect(() => {
        const _usedData = data?.used??data;
        if (!_usedData || !_usedData.loaded) return;
        setDataLoaded(_usedData.loaded);
        const _data = _usedData?.product?.body;
        setDefaultValues(_data);
    }, [data]);

    return (
        <Box sx={{ backgroundColor: "white.main", padding: 2 }}>
            {dataLoaded && (
                <SchemaForm
                    defaultValues={defaultValues}
                    onChange={(_values) => setValues(_values)}
                    onSuccess={() => {
                        alertHandler.push({ severity: "success", message: "Posten är nu uppladdad" });
                        setValues({});
                    }}
                    onRequestFail={() => {
                        alertHandler.push({
                            severity: "error",
                            message: "Misslyckades med uppladdningen, försök igen.",
                        });
                    }}
                    id="create-new-used-product"
                    schema={schema}
                />
            )}
            <List sx={{ width: "60%", marginTop: 4 }}>
                <UsedRow
                    onClick={() => setPreviewDialogOpen(true)}
                    product={{
                        ...values,
                        path: values.images?.[0]?.new ? values.images?.[0].path : `/uploads/${values.images?.[0].path}`,
                    }}
                />
            </List>
            <Stack>
                <UsedDialog
                    open={previewDialogOpen}
                    onClose={() => setPreviewDialogOpen(false)}
                    manualProduct={{
                        ...values,
                        uploads: values?.images?.map((image) => (image?.new ? image.path : `/uploads/${image.path}`)),
                    }}
                ></UsedDialog>
            </Stack>
            <Button
                sx={{ width: "100%" }}
                variant="contained"
                color="triadicGreen"
                type="submit"
                form="create-new-used-product"
            >
                Ladda upp
            </Button>
        </Box>
    );
}

export const action = async (input) => {
    const { urlId } = input.params;
    if (!input.formData) input["formData"] = await input.request.formData();
    if (urlId) return await _API.used.edit({ urlId, formData: input.formData });
    return await _API.used.add({ formData: input.formData });
};

export const loader = async ({ params }) => {
    const urlId = params?.urlId;
    if (!urlId) return { loaded: true };
    return { loaded: true, product: await _API.used.getUrl({ id: urlId }) };
};
