import { v4 as uuidv4 } from "uuid";
export default class Route {
    async fetch(url, options) {
        const res = await fetch(url, { follow: "manual", ...options });
        if (!res.ok) throw new Error(`Server responded with error ${res.status}:${res.statusText}`);
        if (res.redirected) window.location.href = res.url;
        const body = await res.json();
        return { body, __id: uuidv4(), ok: 1 };
    }
}
