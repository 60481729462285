import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";

import { useEffect, useRef, useState } from "react";
import useLanguage from "../../hooks/useLanguage";
import { US, SE, NO, DK, FI } from "country-flag-icons/react/3x2";
import { Menu, MenuItem, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const languages = [
    {
        name: "Svenska",
        short: "swe",
        Icon: SE,
    },
    {
        name: "Engelska",
        short: "eng",
        Icon: US,
    },
    {
        name: "Norska",
        short: "nor",
        Icon: NO,
    },
    {
        name: "Danska",
        short: "da",
        Icon: DK,
    },
    {
        name: "Finska",
        short: "fin",
        Icon: FI,
    },
];

function LanguageSelector({ ...props }) {
    const anchorEl = useRef();
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [currentLanguage, _, setCurrentLanguage] = useLanguage();

    const [currentFullLanguage, setCurrentFullLanguage] = useState(languages[0]);

    useEffect(() => {
        let _currentFullLanguage = languages[0];
        if (currentLanguage) _currentFullLanguage = languages.find((lang) => lang.short === currentLanguage);

        setCurrentFullLanguage(_currentFullLanguage);
    }, [currentLanguage]);

    return (
        <>
            <Button onClick={() => setOpen(!open)} ref={anchorEl}>
                {currentFullLanguage && <currentFullLanguage.Icon style={{ width: "30px" }} />}
                <ExpandMoreIcon sx={{ color: "offblack.1" }} />
            </Button>
            <Menu open={open} onClose={() => setOpen(false)} anchorEl={anchorEl.current}>
                {languages.map((language) => (
                    <MenuItem
                        onClick={() => {
                            setCurrentLanguage(language.short);
                            setOpen(false);
                        }}
                        selected={language.short === currentLanguage}
                        key={language.name}
                    >
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
                            <language.Icon style={{ width: "30px" }} />
                            <ListItemText sx={{ paddingBottom: 0, marginBottom: 0 }}>{language.name}</ListItemText>
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default LanguageSelector;
