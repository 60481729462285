import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ButtonBase from "@mui/material/ButtonBase";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect } from "react";
import TranslatedTypography from "../../Language/TranslatedTypography";

const FieldPreset = ({ preset, definition, currentValues, onChange, ...props }) => {
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        const isPresetSelected = (currentPreset, compareSet) => {
            for (const key of Object.keys(compareSet.sets)) {
                if (currentPreset[key] !== compareSet.sets[key]) return false;
            }
            return true;
        };

        const checkIfOtherPresetSelected = (currentValues, allPresets, currPreset) => {
            for (const checkPreset of allPresets) {
                if (currPreset.value === checkPreset.value) continue;
                if (isPresetSelected(currentValues, checkPreset)) return false;
            }
            return true;
        };

        const currPresetValue = currentValues[definition.name];
        if (!preset.sets) {
            return setIsSelected(checkIfOtherPresetSelected(currPresetValue, definition.presets, preset));
        }

        return setIsSelected(isPresetSelected(currPresetValue, preset));
    }, [preset, definition?.name, currentValues, definition?.presets]);

    return (
        <ButtonBase
            {...props}
            onClick={() => onChange(definition.name, preset?.sets ?? { height: 0, width: 0, length: 0 })}
            variant={isSelected ? "filled" : "outlined"}
            color="primary"
            sx={{
                height: "100%",
                backgroundColor: isSelected ? "primary.superlight" : "white.main",
                ":last-child": {
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                },
            }}
        >
            <TranslatedTypography>{preset.title}</TranslatedTypography>
        </ButtonBase>
    );
};

export default function Fields({ onChange, currentValues, definition, children, ...props }) {
    const smBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));

    const fieldChange = (fieldName, event) => {
        const _currentVal = { ...currentValues[definition.name] };
        const newVal = Number(event.currentTarget.value);
        if (isNaN(newVal)) {
            return false;
        }
        _currentVal[fieldName] = newVal;
        onChange(definition.name, _currentVal);
    };

    return (
        <Stack direction="column">
            <Grid2
                container
                direction={smBreakpoint ? "row" : "column"}
                justifyContent="space-between"
                alignItems="felx-start"
                spacing={2}
                sx={{ marginTop: 1 }}
            >
                <Grid2 {...(smBreakpoint ? { xs: 12 / (definition.fields.length + 1) } : { xs: 12 })}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "150px",
                            borderRadius: 3,
                            boxShadow: 3,
                            backgroundColor: "white.main",
                        }}
                    >
                        <TranslatedTypography variant="h5">Förinställda</TranslatedTypography>
                        <Stack direction="column" justifyContent="stretch" sx={{ height: "118px" }}>
                            {definition.presets.map((preset, index) => (
                                <FieldPreset
                                    definition={definition}
                                    preset={preset}
                                    currentValues={currentValues}
                                    onChange={onChange}
                                />
                            ))}
                        </Stack>
                    </Box>
                </Grid2>
                {definition.fields.map((field) => {
                    return (
                        <Grid2 {...(smBreakpoint ? { xs: 12 / (definition.fields.length + 1) } : { xs: 12 })}>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "150px",
                                    borderRadius: 3,
                                    boxShadow: 3,
                                    backgroundColor: "white.main",
                                }}
                            >
                                <Stack
                                    sx={{ height: "150px" }}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignContent="center"
                                    spacing={3}
                                >
                                    <TranslatedTypography variant="h5">{field.title}</TranslatedTypography>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ backgroundColor: "white.main", paddingLeft: 1, paddingRight: 1 }}
                                        label={<TranslatedTypography>{field.title}</TranslatedTypography>}
                                        value={currentValues[definition.name][field.name]}
                                        onChange={(event) => fieldChange(field.name, event)}
                                    ></TextField>
                                </Stack>
                            </Box>
                        </Grid2>
                    );
                })}
            </Grid2>
        </Stack>
    );
}

export const prettifyFields = (definition, values) => {
    const returnArray = [];
    const value = values[definition.name];

    for (const entry of definition.fields) {
        returnArray.push({ title: entry.title, value: `${value[entry.name]}${definition.suffix ?? ""}` });
    }
    return returnArray;
};
