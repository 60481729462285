import Stack from "@mui/material/Stack";
import TranslatedTypography from "../../Language/TranslatedTypography";
import TextField from "@mui/material/TextField";

export default function ChoiceTextfield({ onChange, currentValues, definition, children, ...props }) {
    const fieldChange = (event) => {
        onChange(definition.name, event.currentTarget.value);
    };

    return (
        <Stack direction="column">
            <TranslatedTypography align="center">{definition.desc}</TranslatedTypography>
            <TextField
                sx={{ backgroundColor: "white.main", width: "100%" }}
                multiline
                onChange={fieldChange}
                placeholder={definition.placeholder ?? ""}
            ></TextField>
        </Stack>
    );
}

export const prettifyTextfield = (definition, values) => {
    return [{ title: definition.title, value: values[definition.name] }];
};
