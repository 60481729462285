import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import TranslatedTypography from "../Language/TranslatedTypography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MuiSkeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import FormattedText from "../formattedText/FormattedText";
import { useContext, useMemo, useState, useEffect } from "react";
import { UserContext } from "../accountManager/AccountManager";
import Edit from "@mui/icons-material/Edit";
import { useActionData, useNavigate, useSubmit } from "react-router-dom";
import Image from "../Image";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material";
import { _API } from "../../App";

const Description = ({ description }) => {
    return useMemo(
        () => (
            <FormattedText
                value={description}
                readOnly={true}
                theme={false}
                styles={{ ".ql-editor": { padding: 0 } }}
            />
        ),
        [description]
    );
};

function ProductRow({ product, width, forceReloadOfData, ...props }) {
    const navigate = useNavigate();
    const { admin } = useContext(UserContext);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [removeDialogLoading, setRemoveDialogLoading] = useState(false);
    const submit = useSubmit();
    const actionData = useActionData();

    useEffect(() => {
        setRemoveDialogLoading(false);
        if (actionData?.ok && product?.url && actionData.body?.id === product.url) {
            forceReloadOfData();
            setRemoveDialogOpen(false);
        }
    }, [actionData, forceReloadOfData, product?.url]);

    return useMemo(
        () => (
            <ListItemButton
                sx={{
                    backgroundColor: "white.main",
                    boxShadow: 3,
                    height: "150px",
                    margin: 0,
                    borderRadius: 2,
                    marginBottom: 2,
                    padding: 0,
                    width: `${width}px`,
                }}
                {...props}
            >
                <Grid
                    container
                    sx={{ height: "150px", margin: 0, padding: 0 }}
                    direction="row"
                    justifyContent="flex-start"
                    wrap="nowrap"
                    spacing={0}
                >
                    <Grid item sx={{ width: "200px" }}>
                        <Box>
                            <Image
                                src={product?.path}
                                sx={{
                                    borderTopLeftRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    objectFit: "scale-down",
                                    objectPosition: "left",
                                    width: "200px",
                                    height: "150px",
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box
                            sx={{
                                width: `${width - 200 - 16 - 16}px`,
                                marginLeft: "16px",
                                marginRight: "16px",
                                height: "130px",
                                overflowY: "hidden",
                            }}
                        >
                            {product?.titel ? (
                                <TranslatedTypography
                                    noWrap
                                    variant="h5"
                                    sx={{ fontSize: { sx: "0.8em", sm: "1.5em", md: "2em" } }}
                                >
                                    {product?.titel}
                                </TranslatedTypography>
                            ) : (
                                <MuiSkeleton variant="h5" sx={{ marginTop: 4, marginBottom: 1 }} />
                            )}
                            <Description description={product?.beskrivning} />
                        </Box>
                    </Grid>
                </Grid>
                {admin && (
                    <Stack direction="row" sx={{ position: "absolute", right: "10px" }}>
                        <IconButton
                            onClick={(event) => {
                                event.stopPropagation();
                                navigate(`/used/${product.url}/edit`);
                            }}
                        >
                            <Edit />
                        </IconButton>
                        <IconButton
                            onClick={(event) => {
                                event.stopPropagation();
                                setRemoveDialogOpen(product.url);
                            }}
                        >
                            <RemoveCircleIcon />
                        </IconButton>
                        <Dialog open={removeDialogOpen ?? false}>
                            <DialogContent>
                                Vill du verkligen ta bort "{product.titel}"{removeDialogLoading && <CircularProgress />}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        const formData = new FormData();
                                        formData.append("intent", "remove_product");
                                        formData.append("urlId", product.url);
                                        submit(formData, { method: "post", encType: "multipart/form-data" });
                                        setRemoveDialogLoading(true);
                                    }}
                                    color="lightred"
                                    variant="contained"
                                >
                                    Ta bort
                                </Button>
                                <Button
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setRemoveDialogOpen(false);
                                    }}
                                    variant="outlined"
                                >
                                    Avbryt
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Stack>
                )}
            </ListItemButton>
        ),
        [
            admin,
            width,
            navigate,
            product?.url,
            product?.titel,
            product?.beskrivning,
            product?.path,
            props,
            removeDialogOpen,
            submit,
            removeDialogLoading,
        ]
    );
}
export default ProductRow;

export const action = async (input) => {
    if (!input.formData) input["formData"] = await input.request.formData();
    const urlId = input.formData.get("urlId");
    return await _API.used.remove({ urlId });
};
