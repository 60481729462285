import joiSwedish from "./joiSwedish";
import { phone } from "phone";
import { parseJsonCondition } from "./SchemaForm";

export default class SchemaValidator {
    constructor(schema) {
        this.schema = schema;
    }

    _getType(entry) {
        if (["text", "formattedText", "hidden", "toggleGroup", "password"].includes(entry.type))
            return this.Joi.string();
        if (["image", "file"].includes(entry.type)) return this.Joi.any();
        if (["number"].includes(entry.type)) return this.Joi.number().integer();
        if (["checkbox"].includes(entry.type)) return this.Joi.boolean();
        if (["email"].includes(entry.type)) return this.Joi.string().email({ tlds: { allow: false } });

        if (["phone"].includes(entry.type))
            return this.Joi.string().custom((value, helper) => {
                // you can use any libs for check phone
                let number = value;
                if (number && number.length > 0 && number[0] === "0") {
                    number = number.slice(1);
                    number = `+46${number}`;
                }

                const formattedPhone = phone(number);
                if (!formattedPhone.isValid) return helper.error("phone.invalid");
                return formattedPhone.phoneNumber;
            });

        console.log("Invalid type", entry.type);
    }

    _getEntry(entry) {
        let type = this._getType(entry);
        type = type.label(entry.label ?? entry.name);
        if (entry.required) type = type.required();
        if (entry.min) type = type.min(entry.min);
        if (entry.max) type = type.max(entry.max);
        if (entry.urlSafe) type = type.uri({ allowRelative: entry.relative ?? false });
        return type;
    }

    _joifySchema(values) {
        const newSchema = {};
        for (const entry of this.schema) {
            let pass = true;
            if (entry.condition) {
                pass = parseJsonCondition(entry.condition, values);
            }
            if (pass) {
                newSchema[entry.name] = this._getEntry(entry);
                if (["file", "image"].includes(entry.type)) newSchema[`${entry.name}_definitions`] = this.Joi.any();
            }
        }
        return this.Joi.object(newSchema);
    }

    async validate(formValues) {
        return import("joi").then((Joi) => {
            this.Joi = Joi;
            const joiSchema = this._joifySchema(formValues);

            return joiSchema.validate(formValues, {
                abortEarly: false,
                errors: { language: "se" },
                messages: { se: joiSwedish },
            });
        });
    }
}
