import Route from "./Route";

export default class Used extends Route {
    constructor(path) {
        super();
        this.path = `/api/v1/${path}`;
    }

    getAll({ skip, limit, category }) {
        return this.fetch(`${this.path}?skip=${skip}&limit=${limit}&category=${category}`);
    }

    count({ category }) {
        return this.fetch(`${this.path}/count?category=${category}`);
    }

    _populateUploadsAndThumbnails(fetchPromise) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetchPromise;
                response.body["uploads"] = response.body.images.map((image) => {
                    return `/uploads/${image.path}`;
                });
                response.body["thumbnails"] = response.body.images.map((image) => {
                    return `/thumbnails/${image.path}`;
                });
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    get({ id }) {
        return this._populateUploadsAndThumbnails(this.fetch(`${this.path}/${id}`));
    }

    getUrl({ id }) {
        return this._populateUploadsAndThumbnails(this.fetch(`${this.path}/${id}/url`));
    }

    requestQuote({ urlId, formData }) {
        return this.fetch(`${this.path}/${urlId}/requestQuote`, { method: "POST", body: formData });
    }

    add({ formData }) {
        return this.fetch(`${this.path}/`, { method: "POST", body: formData });
    }

    edit({ urlId, formData }) {
        return this.fetch(`${this.path}/${urlId}`, { method: "POST", body: formData });
    }

    remove({ urlId }) {
        return this.fetch(`${this.path}/${urlId}/remove`, { method: "POST", body: {} });
    }
}
