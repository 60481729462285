const joiSwedish = {
    "string.empty": "Kan inte vara tom",
    "any.required": "{#label} är obligatorisk",
    "number.base": "Måste vara ett nummer",
    "any.invalid": "Värdet är inte giltigt",
    "domain.invalid": "Domänen är inte giltig",
    "string.email": "Eposten är inte giltig",
    "phoneNumber.invalid": "Telefonnummret är inte giltigt",
    "array.unique": "Innehåller dubletter",
    "string.uri":
        "{#label} måste vara giltig i url. Inga mellanslag, inga special symboler(/,:\\{}[]$), inga svenska tecken (åäö), undesträck och bindesträck går bra.",
    "phone.invalid": "Ogiltigt telefonnummer",
};

export default joiSwedish;
