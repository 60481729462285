import createTheme from "@mui/material/styles/createTheme";

export default createTheme({
    palette: {
        primary: {
            main: "#2aa82a",
            superlight: "#d9f5d9",
        },
        secondary: {
            main: "#FF0000",
        },
        lightred: {
            main: "#FF0000",
        },
        darkred: {
            main: "#8B0000",
        },
        offwhite: {
            main: "#F6F4F3",
        },
        white: {
            main: "#FFFFFF",
        },
        triadicGreen: {
            main: "#2aa82a",
            dark: "#33cb33",
            light: "#2aa82a",
            contrastText: "#FFFFFF",
        },
        lightgray: {
            main: "#a8a3a0",
        },
        offblack: {
            main: "#423E3C",
            1: "#423E3C",
        },
    },
    typography: {
        allVariants: {
            color: "#212427",
        },
    },
});
