import Stack from "@mui/material/Stack";

import OilBarrelIcon from "@mui/icons-material/OilBarrel";
import PropaneIcon from "@mui/icons-material/Propane";
import BoltIcon from "@mui/icons-material/Bolt";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import DoorFrontIcon from "@mui/icons-material/DoorFront";
import DoorBackIcon from "@mui/icons-material/DoorBack";

const getCombinedIcon = (firstIcons = [], secondIcons = []) => {
    return (props) => {
        return (
            <Stack direction="column" spacing={0} alignItems="center" justifyContent="center">
                <Stack direction="row" spacing={0} alignItems="center" justifyContent="center">
                    {firstIcons.map((Icon) => (
                        <Icon {...props} />
                    ))}
                </Stack>
                <Stack direction="row" spacing={0} alignItems="center" justifyContent="center">
                    {secondIcons.map((Icon) => (
                        <Icon {...props} />
                    ))}
                </Stack>
            </Stack>
        );
    };
};

const schema = [
    {
        title: "ATEX Explosionssäker",
        name: "atex_explotionproof",
        desc: 'ATEX explotionsäker innebär att ugnen uppfyller kraven för "ATEX II 3G Zone 2" vilket betyder att den är passande för gas miljöer där det finns en sällsynt eller onormal risk för att en expolsition inträffar. Läs mer: https://atexindustries.com/solutions/atex-proof/',
        default: "no",
        dense: true,
        options: [
            {
                prettyValue: "Nej.",
                value: "no",
                title: "Jag behöver ingen explotions certifiering",
                Icon: SkipNextIcon,
            },
            {
                prettyValue: "Ja.",
                image: "/public/new/chamberovens/ATEX_logo.png",
                value: "yes",
                title: "Jag behöver ATEX II 3G Zone 2 certifiering",
                desc: "",
            },
        ],
    },
    {
        title: "Drivmedel",
        name: "power",
        desc: "",
        default: "electric",
        dense: true,
        options: [
            {
                prettyValue: "Elektricitet",
                value: "electric",
                title: "Elektricitet",
                desc: "",
                Icon: BoltIcon,
            },
            {
                value: "gas",
                prettyValue: "Gas",
                title: "Gas",
                Icon: PropaneIcon,
                suboption: {
                    title: "Gastyp",
                    name: "gas_type",
                    default: "ng/lpg",
                    options: [
                        {
                            title: "Enbart naturgas",
                            value: "ng",
                            prettyValue: "Naturgas",
                        },
                        {
                            prettyValue: "Naturgas och gasol",
                            title: "Naturgas & gasol",
                            value: "ng/lpg",
                        },
                    ],
                },
                rules: [
                    {
                        desc: 'ATEX Explosionssäker går enbart få med drivmedel "Elektricitet""',
                        condition: { "==": [{ var: "atex_explotionproof" }, "no"] },
                    },
                ],
            },
            {
                value: "oil",
                prettyValue: "Olja",
                title: "Olja",
                Icon: OilBarrelIcon,
                rules: [
                    {
                        desc: 'ATEX Explosionssäker går enbart få med drivmedel "Elektricitet""',
                        condition: { "==": [{ var: "atex_explotionproof" }, "no"] },
                    },
                ],
            },
        ],
    },
    {
        title: "Max arbetstemperatur",
        name: "max_tempature",
        default: "200",
        dense: true,
        options: [
            {
                value: "200",
                title: "200 grader celsius",
                Icon: LocalFireDepartmentIcon,
            },
            {
                value: "400",
                title: "400 grader celsius",
                Icon: getCombinedIcon([LocalFireDepartmentIcon, LocalFireDepartmentIcon]),
            },
            {
                value: "600",
                title: "600 grader celsius",
                Icon: getCombinedIcon([LocalFireDepartmentIcon], [LocalFireDepartmentIcon, LocalFireDepartmentIcon]),
                desc: "",
                rules: [
                    {
                        desc: '600 Grader finns bara tillgänglig med drivmedel "Elektricitet"',
                        condition: { "==": [{ var: "power" }, "electric"] },
                    },
                ],
            },
        ],
    },
    {
        title: "Dörrar",
        name: "doors",
        dense: true,
        desc: "",
        default: "front",
        options: [
            {
                value: "front",
                prettyValue: "Bara fram",
                title: "Enbart fram",
                Icon: DoorFrontIcon,
                suboption: {
                    type: "option",
                    default: "manual",
                    name: "doorOpening",
                    title: "Dörröppning",
                    options: [
                        {
                            title: "Manuell",
                            prettyValue: "Manuell",
                            value: "manual",
                        },
                        {
                            prettyValue: "Tryckluft",
                            title: "Tryckluft",
                            value: "pressure",
                        },
                    ],
                },
            },
            {
                value: "frontAndBack",
                prettyValue: "Bak och fram",
                title: "Både fram och bak",
                Icon: getCombinedIcon([DoorFrontIcon, DoorBackIcon]),
                suboption: {
                    default: "manual",
                    name: "doorOpening",
                    title: "Dörröppning",
                    options: [
                        {
                            prettyValue: "Manuell",
                            title: "Manuell",
                            value: "manual",
                        },
                        {
                            prettyValue: "Tryckluft",
                            title: "Tryckluft",
                            value: "pressure",
                        },
                    ],
                },
            },
        ],
    },
    {
        title: "Invänding utrustning",
        name: "equipment",
        dense: true,
        desc: "",
        default: "empty",
        options: [
            {
                value: "empty",
                title: "Tom",
                prettyValue: "Tom",
                Icon: CheckBoxOutlineBlankIcon,
            },
            {
                value: "wagon",
                title: "Vagn",
                prettyValue: "Vagn",
                image: "/public/new/chamberovens/wagon.jpg",
                desc: "",
            },
            {
                value: "conveyor",
                title: "Conveyor",
                prettyValue: "Conveyor",
                image: "/public/new/chamberovens/conveyor.jpg",
                suboption: {
                    name: "noConveyors",
                    title: "Antal",
                    type: "number",
                    max: 6,
                    min: 1,
                    default: 1,
                },
            },
        ],
    },
    {
        title: "Invänding storlek",
        name: "size",
        default: { length: 1800, width: 1200, height: 1200 },
        type: "fields",
        fields: [
            {
                title: "Höjd (mm)",
                prettyValue: "Höjd (mm)",
                name: "height",
            },
            {
                title: "Bredd (mm)",
                prettyValue: "Bredd (mm)",
                name: "width",
            },
            {
                prettyValue: "Längd (mm)",
                title: "Längd (mm)",
                name: "length",
            },
        ],
        presets: [
            {
                value: "small",
                title: "Liten",
                sets: {
                    length: 1800,
                    width: 1200,
                    height: 1200,
                },
            },
            {
                value: "large",
                title: "Stor",
                sets: {
                    length: 3000,
                    width: 1800,
                    height: 1300,
                },
            },
            {
                value: "custom",
                title: "Fyll i själv",
            },
        ],
    },
    {
        title: "Övriga kommentarer",
        desc: "Om du har frågor, funderingar eller special-beställningar så fyll i dem nedan så kontaktar vi er så fort som möjligt med svar.",
        name: "comment",
        default: "",
        placeholder:
            "Vi är osäkra på om vagn eller conveyor är rätt för vår verksamhet, vad är för och nackdelarna med vardera?",
        type: "textfield",
    },
];

export default schema;
