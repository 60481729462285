// Modules object for setting up the Quill editor
export const modules = {
    toolbar: [
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { align: "" }, { align: "center" }],
        ["link", "image"],
        ["clean"],
        [{ color: ["black", "#CB3333", "red", "green", "blue", "yellow"] }],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

// Formats objects for setting up the Quill editor
export const formats = [
    "header",
    "font",
    "size",
    "bold",
    "underline",
    "italic",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block",
];
