import ChamberOven from "./ChamberOven";
import Used from "./Used";
import User from "./User";
import Pdf from "./Pdf";
import ShowRoom from "./ShowRoom";
export default class API {
    constructor(url, secure = false) {
        this._url = url;
        this._token = undefined;
        this._used = new Used("used");
        this._user = new User("user");
        this._chamberOven = new ChamberOven("chamberoven");
        this._showroom = new ShowRoom("showroom");
        this._pdf = new Pdf("pdf");

        if (!secure) {
            this._url = this._url.replace(/^https?/, "http");
        }
    }

    get pdf() {
        return this._pdf;
    }
    get used() {
        return this._used;
    }

    get user() {
        return this._user;
    }

    get chamberOven() {
        return this._chamberOven;
    }

    get showroom() {
        return this._showroom;
    }
}
