import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";

import SchemaForm from "../SchemaForm/SchemaForm";
import { _API } from "../../App";
import { useContext } from "react";
import { AlertContext } from "../AlertHandler/AlertHandler";
import { useLoaderData } from "react-router-dom";

const schema = [
    {
        label: "Namn",
        name: "name",
        required: true,
        type: "text",
    },
    {
        label: "Email",
        name: "email",
        type: "email",
    },
    {
        label: "Password",
        name: "password",
        type: "password",
    },
    {
        label: "Admin",
        type: "checkbox",
        name: "admin",
    },
    {
        name: "intent",
        required: true,
        type: "hidden",
        defaultVal: "add_user",
    },
];

export default function UserChanger(props) {
    const alertHandler = useContext(AlertContext);
    const loaderData = useLoaderData();

    return (
        <>
            <SchemaForm
                onSuccess={() => {
                    alertHandler.push({ severity: "success", message: "Posten är nu uppladdad" });
                }}
                onRequestFail={() => {
                    alertHandler.push({ severity: "error", message: "Misslyckades med uppladdningen, försök igen." });
                }}
                id="add-new-user-form"
                schema={schema}
            />
            <Button
                sx={{ width: "100%", marginTop: 2 }}
                variant="contained"
                color="triadicGreen"
                type="submit"
                form="add-new-user-form"
            >
                Lägg till
            </Button>

            <Box>
                <Typography variant="h3">Existerande:</Typography>
                <List>
                    {loaderData?.userChanger &&
                        loaderData.userChanger.map((user) => (
                            <ListItem sx={{ boxShadow: 2 }}>
                                <Stack direction="column">
                                    <Typography>
                                        <b>Namn: </b>
                                        {user.name}
                                    </Typography>
                                    <Typography>
                                        <b>Email: </b>
                                        {user.email}
                                    </Typography>
                                    <Typography>
                                        <b>Admin: </b>
                                        {user.admin}
                                    </Typography>
                                    <Typography>
                                        <b>Superadmin: </b>
                                        {user.superadmin}
                                    </Typography>
                                </Stack>
                            </ListItem>
                        ))}
                </List>
            </Box>
        </>
    );
}

export const loader = () => {
    return _API.user.getAll();
};

export const action = async ({ formData, params, request }) => {
    return await _API.user.add({ formData });
};
