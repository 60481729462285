import { useState, useEffect } from "react";

export function useOnScroll() {
    const [scrolling, setScrolling] = useState(0);

    useEffect(() => {
        const onScroll = (e) => {
            setScrolling(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);
    return scrolling;
}
