import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

import useTheme from "@mui/material/styles/useTheme";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AlertContext } from "../AlertHandler/AlertHandler";
import SchemaForm from "../SchemaForm/SchemaForm";

const schema = [
    {
        label: "Namn",
        name: "name",
        required: true,
        type: "text",
    },
    {
        label: "Email adress",
        name: "email",
        required: true,
        type: "email",
    },
];

export default function NewsletterDialog({ id, manualProduct, onClose, open, ...props }) {
    const theme = useTheme();
    const breakPointMD = useMediaQuery(theme.breakpoints.up("md"));
    const breakPointSM = useMediaQuery(theme.breakpoints.up("sm"));
    const navigate = useNavigate();
    const { push } = useContext(AlertContext);

    const handleClose = () => {
        if (onClose) return onClose();
        navigate("/used");
    };

    const onSuccess = () => {
        push({ type: "success", message: "Tillagd i nyhetsbrevet" });
    };

    const onFail = () => {
        push({ type: "error", message: "Något gick fel, försök igen." });
    };

    const handleNewsletterSubmit = () => {};

    return (
        <>
            <Dialog
                maxWidth="md"
                fullScreen={!breakPointMD}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") handleClose();
                }}
                open={open ?? true}
            >
                <DialogTitle variant="h3" sx={{ fontSize: { xs: "1.5em", sm: "2em", lg: "3em" } }}>
                    Nyhetsbrev
                </DialogTitle>
                <DialogContent sx={{ padding: breakPointSM ? 2 : 0 }}>
                    <SchemaForm
                        onSuccess={onSuccess}
                        onRequestFailure={onFail}
                        id="newsletter-registration-form"
                        schema={schema}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="triadicGreen" variant="contained" onClick={handleNewsletterSubmit}>
                        Lägg till
                    </Button>
                    <Button color="offblack" variant="outlined" onClick={handleClose}>
                        Stäng
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export const action = async (input) => {
    //const formData = await input.request.formData();
    //throw new Error("Invalid intent");
};
