import MuiSkeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Suspense, lazy, useEffect, useState } from "react";
import { modules, formats } from "./CustomToolbar";
import useLanguage from "../../hooks/useLanguage";

const ReactQuill = lazy(() => import("react-quill"));

export const Skeleton = () => {
    return (
        <Stack direction="column" spacing={1} justifyContent={"flex-start"}>
            <MuiSkeleton height={15} width="80%" variant="rectangular" />
            <MuiSkeleton height={15} width="80%" variant="rectangular" />
            <MuiSkeleton height={15} width="60%" variant="rectangular" />
        </Stack>
    );
};

export default function FormattedText({ value, readOnly = true, ...props }) {
    const [translated, setTranslated] = useState(value);
    // eslint-disable-next-line no-unused-vars
    const [_, translate] = useLanguage();

    useEffect(() => {
        if (!value) return;
        const asyncWrapper = async () => {
            const _translated = await translate(value);
            setTranslated(_translated);
        };
        asyncWrapper();
    }, [value, translate]);

    return (
        <Suspense fallback={<Skeleton />}>
            {readOnly ? (
                <>
                    {translated !== undefined ? (
                        <ReactQuill
                            readOnly={true}
                            modules={{
                                clipboard: {
                                    // toggle to add extra line breaks when pasting HTML:
                                    matchVisual: false,
                                },
                            }}
                            formats={formats}
                            value={translated}
                            {...props}
                        />
                    ) : (
                        <Skeleton />
                    )}
                </>
            ) : (
                <ReactQuill
                    readOnly={false}
                    modules={modules}
                    formats={formats}
                    defaultValue={props?.defaultValue}
                    onChange={props?.onChange}
                    theme={props?.theme}
                />
            )}
        </Suspense>
    );
}
