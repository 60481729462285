import Typography from "@mui/material/Typography";
import { _API } from "../../App";
import { useEffect, useState } from "react";
import useLanguage from "../../hooks/useLanguage";

export default function TranslatedTypography({ doTranslation = true, children, ...props }) {
    const [translatedChildren, setTranslatedChildren] = useState(children);
    // eslint-disable-next-line no-unused-vars
    const [_, translate] = useLanguage();

    useEffect(() => {
        const asyncWrapper = async () => {
            const translated = await translate(children);
            setTranslatedChildren(translated);
        };
        asyncWrapper();
    }, [children, translate]);

    return <Typography {...props}>{doTranslation ? translatedChildren : children}</Typography>;
}

export const action = async ({ params, request }) => {
    const formData = await request.formData();
    return await _API.user.login({ formData });
};
