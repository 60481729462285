import Route from "./Route";

export default class ChamberOven extends Route {
    constructor(path) {
        super();
        this.path = `/api/v1/${path}`;
    }

    requestQuote({ formData }) {
        return this.fetch(`${this.path}/requestQuote`, { method: "POST", body: formData });
    }
}
