import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet } from "react-router-dom";
import MapIcon from "@mui/icons-material/Map";
import { siteWidthOptions } from "../header/Header";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Image from "../Image";
import TranslatedTypography from "../Language/TranslatedTypography";

const contacts = [
    {
        name: "Peter Olsson",
        image: "/public/contacts/peter.jpg",
        mail: "hybe@industriugnar.com",
        phone: "+46 70 247 03 45",
        desc: (
            <TranslatedTypography>
                Ansvarig för begagnat,
                <br />
                lokaler
            </TranslatedTypography>
        ),
    },
    {
        name: "Oskar Olsson",
        image: "/public/contacts/oskar.jpg",
        mail: "oskar@industriugnar.com",
        phone: "+46 73 334 33 45",
        desc: <TranslatedTypography>Ansvarig för nytt, IT</TranslatedTypography>,
    },
];

const Office = ({ src, title, children, map }) => {
    return (
        <Card sx={{ paddingBottom: 2 }}>
            <CardMedia component="img" image={src} />
            <CardHeader sx={{ padding: 0 }} title={title}></CardHeader>
            <CardContent sx={{ paddingTop: 0 }}>{children}</CardContent>
            <Button component="a" href={map} target="_blank" variant="contained" startIcon={<MapIcon />}>
                <TranslatedTypography color="white.main">Hitta oss</TranslatedTypography>
            </Button>
        </Card>
    );
};

const Ofiices = () => {
    return (
        <Box sx={{ height: { xs: "100%", sm: "560px" } }}>
            <Box
                sx={{
                    position: { xs: "relative", sm: "absolute" },
                    left: 0,
                    width: "100%",
                    backgroundColor: "offblack.main",
                    paddingBottom: 4,
                    paddingTop: 4,
                    marginTop: 4,
                    marginBottom: 4,
                }}
            >
                <Box sx={{ ...siteWidthOptions }}>
                    <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" spacing={3}>
                        <Office
                            src="/public/locations/hogsby.jpg"
                            title="Högsby"
                            map="https://www.google.com/maps/place/Villes+stig,+579+33+H%C3%B6gsby,+Sweden/@57.1694542,16.0094679,18.46z/data=!4m6!3m5!1s0x46578ffcf285cb77:0xc41a8272da3bee4a!8m2!3d57.1694645!4d16.0094401!16s%2Fg%2F1tfc0mwr?entry=ttu"
                        >
                            <TranslatedTypography variant="p">Verkstad/Lager</TranslatedTypography>
                            <Typography>
                                Villes stig
                                <br />
                                SE-579 33 HÖGSBY
                            </Typography>
                        </Office>
                        <Office
                            src="/public/locations/ruda.jpg"
                            title="Ruda"
                            map="https://www.google.com/maps/place/af+Harmens+v%C3%A4g+25,+570+76+Ruda/data=!4m2!3m1!1s0x46579229b4d95ce3:0x414259119cf468f?sa=X&ved=2ahUKEwjXtNHil7TkAhVDblAKHRrlCngQ8gEwAHoECAoQAQ"
                        >
                            <TranslatedTypography variant="p">Verkstad/Lager</TranslatedTypography>
                            <Typography>
                                AF harmensväg 25
                                <br />
                                SE-579 71 RUDA
                            </Typography>
                        </Office>
                        <Office
                            src="/public/locations/bjorkshult.jpg"
                            title="Björkshult"
                            map="https://www.google.com/maps/place/Bruksv%C3%A4gen+9,+579+93+Gr%C3%B6nsk%C3%A5ra,+Sverige/@57.0819566,15.6888186,17z/data=!3m1!4b1!4m5!3m4!1s0x46579e45cb785e2b:0x9da4595d92656119!8m2!3d57.0819537!4d15.6910073"
                        >
                            <TranslatedTypography variant="p">Verkstad/Lager </TranslatedTypography>{" "}
                            <Typography>
                                Bruksvägen 9<br />
                                SE-579 93 GRÖNSKÅRA
                            </Typography>
                        </Office>
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
};

export default function Products(props) {
    return (
        <>
            <Stack sx={{ marginTop: 3 }} alignItems="center" direction="column" spacing={3}>
                <Box sx={{ width: { sm: "100%", md: "66%" } }}>
                    <TranslatedTypography variant="h4" align="left">
                        Varmt Välkommen till HYBE industrial ovens AB.
                    </TranslatedTypography>
                    <br />
                    <TranslatedTypography align="left">
                        <Image src="/public/hybe.png" sx={{ width: "120px" }} /> är störst i Sverige på industriugnar
                        med mer än 200 nya och begagnade ugnar i lager.
                        <br />
                        <br />
                        <b>Våra leverantörer på nya ugnar / pulverlackering</b>
                        <br />
                        <br />
                        <Image src="/public/romer.png" sx={{ width: "100px" }} /> Komplett pulverlacksprogram. Ugnar,
                        pulverboxar, sprutor, tvättar, convyers och kompletta pulverlacksanläggningar <br />
                        Mer än 10 000 enheter sålda i 45 länder.
                        <br />
                        <br />
                        <Image src="/public/heldors.png" sx={{ width: "100px" }} /> Komplett ugnsprogram. Kammarugnar,
                        vagnugnar, härdugnar, anlöpningsugnar. huvugnar, rörugnar, boggihärdsugnar, bordsugnar, mm
                        <br />
                    </TranslatedTypography>
                </Box>
                <TranslatedTypography variant="h2" textAlign="center">
                    Sveriges bästa priser
                </TranslatedTypography>
                <TranslatedTypography variant="h5" textAlign="center">
                    Kontakta oss för offert.
                </TranslatedTypography>
            </Stack>
            <Ofiices />

            <Stack direction="column" alignItems="center" spacing={10}>
                <Box>
                    <Box sx={{ marginBottom: 4 }}>
                        <TranslatedTypography sx={{ marginBottom: 4 }} variant="h2" align="left">
                            Kontakt
                        </TranslatedTypography>
                        <Stack direction={{ xs: "column", md: "row" }} justifyContent="flex-start" spacing={4}>
                            {contacts.map(({ name, image, mail, desc, phone }) => {
                                return (
                                    <Stack>
                                        <Typography variant="h4">{name}</Typography>
                                        <Card>
                                            <CardMedia component="img" image={image} height="450px" />
                                            <CardContent sx={{ paddingTop: 0 }}>
                                                <Box sx={{ height: "40px" }}>{desc}</Box>
                                                <List dense>
                                                    <ListItem dense>
                                                        <ListItemIcon>
                                                            <EmailIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<a href={`mailto:${mail}`}>{mail}</a>} />
                                                    </ListItem>
                                                    <ListItem dense>
                                                        <ListItemIcon>
                                                            <LocalPhoneIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<a href={`tel:${phone}`}>{phone}</a>} />
                                                    </ListItem>
                                                </List>
                                            </CardContent>
                                        </Card>
                                    </Stack>
                                );
                            })}
                        </Stack>
                    </Box>
                    <Box sx={{ marginBottom: 4 }}>
                        <TranslatedTypography align="left" variant="h2">
                            Kontor
                        </TranslatedTypography>
                        <Typography align="left">
                            HYBE industrial ovens AB
                            <br /> Vat. No. SE 556499893701 <br />
                            Frövivägen 20 SE-579 33 HÖGSBY SWEDEN
                        </Typography>
                    </Box>
                </Box>
            </Stack>
            <Outlet></Outlet>
        </>
    );
}
