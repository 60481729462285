import { useEffect, useState } from "react";

export default function ControlledFile({
    value,
    condition,
    name,
    defaultVal,
    label,
    onChange,
    accept,
    multiple,
    ...props
}) {
    const [fileDefinitions, setFileDefinitions] = useState({});

    useEffect(() => {
        const fileName = value?.[0]?.name;
        setFileDefinitions([{ type: "application/pdf", name: fileName, timestamp: Date.now() }]);
    }, [value]);

    //heads up, definition must come before file ¯\_(ツ)_/¯
    return (
        <>
            <input type="hidden" value={JSON.stringify(fileDefinitions)} name={`${name}_definitions`}></input>
            <input
                {...props}
                multiple={multiple ?? false}
                type="file"
                accept={accept}
                name={name}
                onChange={(event) => onChange(event.target.files)}
            ></input>
        </>
    );
}
