import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";

import schema from "./chamberOvenSchema";
import Choice, { prettifyChoice } from "../ChoiceBuilder/Choice/Choice";
import RequestQuoteDialog, { action as requestQuoteDialogAction } from "./RequestQuoteDialog";
import MediaCarosel from "../mediaCarosel/MediaCarosel";
import TranslatedTypography from "../Language/TranslatedTypography";

const PrettyValue = ({ definition, values }) => {
    const [prettyValue, setPrettyValue] = useState();

    useEffect(() => {
        setPrettyValue(prettifyChoice(definition, values));
    }, [definition, values]);

    return (
        <Stack direction="row" spacing={2} justifyContent="flex-start">
            {prettyValue?.map((entry, index) => (
                <TranslatedTypography>
                    {entry.title}: {entry.value}
                    {index !== prettyValue.length - 1 && ","}
                </TranslatedTypography>
            ))}
        </Stack>
    );
};

export default function ChamberOven(props) {
    const getDefaults = () => {
        const defaults = {};
        for (const def of schema) {
            defaults[def.name] = def.default;

            // Handle default suboptions
            if (def.options) {
                for (const option of def.options) {
                    if (option.suboption) defaults[option.suboption.name] = option.suboption.default;
                }
            }
        }
        return defaults;
    };

    const [values, setValues] = useState(getDefaults());
    const [additionalSchemaHiddens, setAdditionalSchemaHiddens] = useState([]);
    const [requestQuote, setRequestQuote] = useState(false);

    useEffect(() => {
        const _hiddens = [];
        for (const valueKey of Object.keys(values)) {
            _hiddens.push({
                type: "hidden",
                defaultVal: values[valueKey],
                name: valueKey,
            });
        }
        setAdditionalSchemaHiddens(_hiddens);
    }, [values]);

    const onChange = (name, value) => {
        const newBlock = { ...values };
        newBlock[name] = value;
        setValues(newBlock);
    };

    return (
        <Box>
            <TranslatedTypography variant="h1" sx={{ fontSize: { xs: "3em", sm: "4em", lg: "6em" } }}>
                Kammarugnar
            </TranslatedTypography>

            <MediaCarosel
                medias={[
                    { type: "image", path: "/public/new/regular.png" },
                    { type: "image", path: "/public/new/largeOpen.jpg" },
                    { type: "image", path: "/public/new/regularOpen.jpg" },
                    { type: "image", path: "/public/new/largeClosed.jpg" },
                ]}
            />

            <TranslatedTypography variant="subtitle1">
                Välkommen till vår sida för kammarugnar, fyll i informationen nedan så återkommer vi så snart som
                möjligt med en offert. Om ni är osäkra på något av valen, har funderingar eller special-beställningar så
                skriv det i övrigtrutan så hör vi av oss så fort som möjligt.
            </TranslatedTypography>
            {schema.map((def) => (
                <Box sx={{ marginTop: 8 }}>
                    <Choice onChange={onChange} currentValues={values} definition={def} />
                </Box>
            ))}
            <Button
                onClick={() => setRequestQuote(true)}
                variant="contained"
                sx={{ height: "80px", width: "100%", marginTop: 4, marginBottom: 6 }}
            >
                <TranslatedTypography color="white.main">Skicka offertförfrågan</TranslatedTypography>
            </Button>
            <RequestQuoteDialog
                additionalSchemaEntries={additionalSchemaHiddens}
                open={requestQuote}
                onClose={() => setRequestQuote(false)}
            >
                {schema.map((definition) => (
                    <PrettyValue definition={definition} values={values} />
                ))}
            </RequestQuoteDialog>
        </Box>
    );
}

export const action = async (input) => {
    const formData = await input.request.formData();
    const intent = formData.get("intent");
    if (intent === "requestQuote") return await requestQuoteDialogAction({ ...input, formData });
    throw new Error("Invalid intent");
};
