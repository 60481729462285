import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useContext, useState } from "react";
import { useLoaderData } from "react-router-dom";

import SchemaForm from "../SchemaForm/SchemaForm";
import { _API } from "../../App";
import { AlertContext } from "../AlertHandler/AlertHandler";

const schema = [
    {
        name: "type",
        type: "toggleGroup",
        defaultVal: "organization",
        members: [
            {
                label: "Organisation",
                name: "organization",
            },
            {
                label: "Privatperson",
                name: "private",
            },
        ],
    },
    {
        label: "Företagsnamn",
        name: "companyName",
        required: true,
        condition: { "==": [{ var: "type" }, "organization"] },
        type: "text",
    },
    {
        label: "Organisationsnummer",
        condition: { "==": [{ var: "type" }, "organization"] },
        name: "vat",
        required: true,
        type: "text",
    },
    {
        label: "Epost",
        name: "email",
        required: true,
        type: "email",
    },
    {
        label: "För- och efternamn",
        name: "fullname",
        required: true,
        type: "text",
    },
    {
        label: "Stad",
        name: "city",
        required: true,
        type: "text",
    },
    {
        label: "Adress",
        name: "address",
        required: true,
        type: "text",
    },
    {
        label: "Postnummer",
        name: "zip",
        required: true,
        type: "text",
    },
    {
        label: "Telefonnummer",
        name: "phone",
        required: true,
        type: "phone",
    },
    {
        label: "Meddelande",
        name: "message",
        type: "text",
        multiline: true,
    },
    {
        label: "Skicka en kopia till mig",
        name: "sendcopy",
        defaultVal: true,
        type: "checkbox",
    },
    {
        name: "intent",
        defaultVal: "requestQuote",
        type: "hidden",
    },
];
export default function RequestQuoteDialog({ id, onClose, open, additionalSchemaEntries, children, ...props }) {
    const product = useLoaderData();
    const alertHandler = useContext(AlertContext);
    const smBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const [formQuoteData, setFormQuoteData] = useState();

    const onSuccess = () => {
        alertHandler.push({ severity: "success", message: "Offertförfrågan skickad!" });
        onClose();
    };

    const onFail = () => {
        alertHandler.push({ severity: "error", message: "Misslyckades med offertförfrågan, var snäll försök igen." });
    };

    return (
        <Dialog fullScreen={!smBreakpoint} maxWidth="md" onClose={onClose} open={open} fullWidth>
            <DialogTitle>Begär offert för {product?.titel}</DialogTitle>
            <DialogContent>
                {children}

                <SchemaForm
                    onChange={setFormQuoteData}
                    defaultValues={formQuoteData}
                    onSuccess={onSuccess}
                    onRequestFail={onFail}
                    id="request-used-qutoe-form"
                    schema={[...schema, ...additionalSchemaEntries]}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="triadicGreen" type="submit" form="request-used-qutoe-form">
                    Skicka
                </Button>
                <Button color="offblack" variant="outlined" autoFocus onClick={onClose}>
                    Stäng
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export const action = async ({ formData, params, request }) => {
    const res = await _API.chamberOven.requestQuote({ formData });
    if (!res.ok) throw res;
    return res;
};
