import TranslatedTypography from "../../Language/TranslatedTypography";
import { prettifyFields, default as Fields } from "./Fields";
import { prettifyTextfield, default as Textfield } from "./Textfield";
import { prettifyOptions, default as Options } from "./Options";

export default function Choice(props) {
    return (
        <>
            <TranslatedTypography align="center" variant="h4">
                {props.definition.title}
            </TranslatedTypography>
            {(props?.definition?.type === undefined || props.definition.type === "option") && (
                <Options {...props}></Options>
            )}
            {props.definition.type === "fields" && <Fields {...props} />}
            {props.definition.type === "textfield" && <Textfield {...props} />}
        </>
    );
}

export const prettifyChoice = (definition, values) => {
    if (definition.type === undefined || definition.type === "option") return prettifyOptions(definition, values);
    if (definition.type === "fields") return prettifyFields(definition, values);
    if (definition.type === "textfield") return prettifyTextfield(definition, values);
};
