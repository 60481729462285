import styled from "@mui/material/styles/styled";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useCallback, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ImageUploader from "./ImageUploader";

const Img = styled("img")``;

export default function ControlledImages({ name, value, onChange, ...props }) {
    const [loading, setLoading] = useState(false);

    const onDragEnd = useCallback(
        (result) => {
            if (!result.destination) return;
            const from = result.source.index;
            const to = result.destination.index;

            const _images = [...value].filter(img => !img.custom);
            const thumbnails = [...value].filter(img => !!img.custom);


            const [removed] = _images.splice(from, 1);
            _images.splice(to, 0, removed);
            onChange([..._images, ...thumbnails]);
        },
        [value, onChange]
    );

    const onImagesAdded = useCallback(
        (newImages) => {
            onChange([
                ...(value ?? []),
                ...(newImages ?? []).map((img) => {
                    img["new"] = true;
                    return img;
                }),
            ]);
        },
        [value, onChange]
    );

    return (
        <>
            <Stack direction="row" alignItems="center" spacing={4}>
                {value && value.length > 0 && (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(provided, snapshot) => (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        overflow: "auto",
                                        width: "100%",
                                    }}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {provided.placeholder}
                                    {value
                                        .filter((img) => !img.custom)
                                        .map((image, index) => (
                                            <Draggable draggableId={image.path} key={image.path} index={index}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={provided.draggableProps.style}
                                                        >
                                                            <Img
                                                                sx={{ height: "150px", display: "block" }}
                                                                src={image?.new ? image.path : `/uploads/${image.path}`}
                                                            ></Img>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
                {loading && <CircularProgress size={60}></CircularProgress>}
            </Stack>
            <ImageUploader
                setLoading={setLoading}
                multiple
                type="file"
                name={name}
                images={value}
                onChange={onImagesAdded}
            ></ImageUploader>
        </>
    );
}
