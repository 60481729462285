import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import CheckIcon from "@mui/icons-material/Check";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import { useContentWidth } from "../../hooks/useContentWidth";
import FullWidthImages from "../fullWidthImages/FullWidthImages";
import TranslatedTypography from "../Language/TranslatedTypography";

const CheckLine = ({ text, ...props }) => {
    return (
        <ListItem>
            <ListItemIcon>
                <CheckIcon></CheckIcon>
            </ListItemIcon>
            <ListItemText>
                <TranslatedTypography>{text}</TranslatedTypography>
            </ListItemText>
        </ListItem>
    );
};

const Stacker = ({ notes }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="felx-start"
            columns={{ xs: 4, sm: 8, md: 12 }}
        >
            {notes.map((note) => (
                <Grid xs={12} md={6} lg={3} direction="row" justifyContent="flex-start">
                    <CheckLine text={note.title} />
                </Grid>
            ))}
        </Grid>
    );
};

const SinglePageShower = ({ title, images, notes, link, ...props }) => {
    const navigate = useNavigate();
    const width = useContentWidth();

    return (
        <Box
            sx={{
                padding: 0,
                marginTop: 8,
            }}
        >
            <Stack direction="column" alignItems="left" justifyContent="left">
                <TranslatedTypography variant="h1" sx={{ fontSize: { xs: "3em", sm: "4em", lg: "6em" } }}>
                    {title}
                </TranslatedTypography>
                <Box
                    sx={{
                        height: "100%",
                        boxShadow: 2,
                        borderRadius: 2,
                        backgroundColor: "white.main",
                        width: "100%",
                    }}
                >
                    <Box {...props} sx={{ left: 0, position: "relative" }}>
                        <FullWidthImages images={images} />
                    </Box>
                    <Box sx={{ position: "absolute" }}>
                        <Button
                            startIcon={<InfoOutlinedIcon />}
                            size="medium"
                            sx={{
                                padding: 1,
                                position: "relative",
                                width: "300px",
                                left: width / 2 - 150,
                                top: -100,
                                fontWeight: "bold",
                            }}
                            variant="contained"
                            onClick={() => navigate(link)}
                        >
                            <TranslatedTypography color="white.main">Mer information</TranslatedTypography>
                        </Button>
                    </Box>
                </Box>
            </Stack>
            <Stacker notes={notes}></Stacker>
        </Box>
    );
};

const chamberOvenNotes = [
    {
        title: "Elektristriska, gasdrivna, oljedrivna",
    },
    {
        title: "Måttbeställda efter dina behov",
    },
    {
        title: "50-600 grader",
    },
    {
        title: "ATEX explosionssäkra",
    },
];

const chamberOvenImages = [
    {
        path: "/public/new/regular.png",
        height: 1437,
        width: 1239,
    },
    {
        path: "/public/new/largeClosed.jpg",
        height: 1000,
        width: 1200,
    },
    {
        path: "/public/new/regularOpen.jpg",
        height: 800,
        width: 800,
    },
];

const powderCoatingNotes = [
    {
        title: "Pulversprutväggar",
    },
    {
        title: "Pulversprutboxar",
    },
    {
        title: "Pulversprutautomater",
    },
    {
        title: "Tvättar",
    },
];

const powderCoatingImages = [
    {
        path: "/public/new/powderCoating2.jpg",
        height: 833,
        width: 1111,
    },
    {
        path: "/public/new/powderCoating1.png",
        height: 615,
        width: 1000,
    },
];

export default function New(props) {
    return (
        <Box>
            <SinglePageShower
                title="Kammarugnar"
                images={chamberOvenImages}
                notes={chamberOvenNotes}
                link="/new/chamberOven"
            />
            <SinglePageShower
                title="Pulverlack"
                images={powderCoatingImages}
                notes={powderCoatingNotes}
                link="/page/pulverlackutrustning"
            />
        </Box>
    );
}
