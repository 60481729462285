import { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import translate from "translate";

const objectConstructor = {}.constructor;

export default function useLanguage() {
    const [currentLanguage, setCurrentLanguage] = useState("swe");
    const [cookies, setCookies] = useCookies();

    const setLanguage = (languageCode = "swe") => {
        setCookies("language", languageCode, { path: "/" });
    };

    const _translate = useCallback(
        async (object) => {
            try {
                if (currentLanguage === "swe") return object; // No translation needed
                if ([null, undefined, "", " "].includes(object)) return object; // No need to fetch translation if object is empty

                if (typeof object === "string" || object instanceof String)
                    return await translate(object, { from: "swe", to: currentLanguage }); // Simple string translation

                if (Array.isArray(object)) {
                    // object is array which might contain string that should be translated
                    let _translated = [...object];
                    for (let i = 0; i < _translated.length; i++) {
                        const child = _translated[i];
                        const translatedChild = await _translate(child);
                        _translated[i] = translatedChild;
                    }
                    return _translated;
                }

                if (object.constructor === objectConstructor) {
                    // Probably a react child object
                    if (!object.props.children) return object; // Just leave it as is if it doesnt have children
                    const translatedChildren = await _translate(object.props.children); // Try to translate the children if it has some
                    const translatedProps = { ...object.props, children: translatedChildren };
                    const translatedObject = { ...object, props: translatedProps };
                    return translatedObject; // Return object with translated children
                }
                //Something unexpected was sent in to the translate function.
                throw new Error(
                    `Invalid input in translate function, sent in object of type "${typeof object}" which is not supported`
                );
            } catch (error) {
                console.log(`Translation error; ${error}`);
                return "";
            }
        },
        [currentLanguage]
    );

    useEffect(() => {
        if (!cookies?.language) return setCurrentLanguage("swe");
        setCurrentLanguage(cookies.language);
    }, [cookies]);
    return [currentLanguage, _translate, setLanguage];
}
