import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import styled from "@mui/material/styles/styled";
import { useNavigate } from "react-router-dom";

const Img = styled("img")``;

//WRONG FONT
function Logo({ children, ...props }) {
    const navigate = useNavigate();

    return (
        <ButtonBase onClick={() => navigate("/")} sx={{ marginTop: 1 }}>
            <Box>
                <Stack direction={"column"} alignItems="flex-start">
                    <Img src={"/public/hybe.png"} sx={{ height: "50px" }} />
                    <Typography sx={{ color: "black" }} variant="h5">
                        <b>industrial ovens AB</b>
                    </Typography>
                </Stack>
            </Box>
        </ButtonBase>
    );
}

export default Logo;
